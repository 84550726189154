import React, { useState, useEffect } from 'react';
import { FormattedMessage } from "react-intl";
import { Galeries } from '../services';
import Parser from 'html-react-parser';
import { Helpers } from '../components';
import { Loading } from '../components';
require('dotenv').config();

const galeries = new Galeries();
const helpers = new Helpers();

export default function Gallery(props) {  
    const [galleryList, setGalleryList] = useState([]);
    const [gallery, setGallery] = useState({});
    const [loading, setLoading] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [type, setType] = useState(0);

    useEffect(() => {
        (async () => getGalleryList())();
    }, []);

    const getGalleryList = () => {
        const type = props.location.search.replace('?type=', '');
        setType(type);
        setLoading(true);

        galeries.list({ limit: 100, type })
        .then(res => {
            console.log(res);            

            setLoading(false);
            setGalleryList(res.result);
        })
        .catch(err => {
            console.error(err);
            if (err.code === 404) {
                setNotFound(true);
            }
            
            setLoading(false);
        })
    }

    const getImage = (fileName) => {
        return `${process.env.REACT_APP_URL_SERVICE}/public/image/galeries/${fileName}`;
    }

    return (
        <div>            
            <main id="main" data-aos="fade-up">
                <section className="breadcrumbs">
                    <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <h2><FormattedMessage id="menu.gallery"/></h2>
                        <ol>
                        <li><a href="/"><FormattedMessage id="menu.home"/></a></li>
                        <li><FormattedMessage id="menu.gallery"/></li>
                        </ol>
                    </div>
                    </div>
                </section>
                <section id="portfolio" className="portfolio">
                    <div className="container" data-aos="fade-up">
                        <div className="row" data-aos="fade-up" data-aos-delay={200}>
                            <Loading visible={loading}/>
                            {!loading && galleryList.length > 0 
                            ? galleryList.map(item => 
                                <div className="col-lg-4 col-md-6 portfolio-item" >
                                    {parseInt(type) === 1 && (
                                        <>
                                            <img 
                                                src={getImage(item.image)}  
                                                className="img-fluid" 
                                                alt="img" 
                                                key={item.id} />

                                            <div className="portfolio-info">
                                                <h4>{item.title}</h4>
                                                <p><FormattedMessage id="component.text.created-by"/>: {item.created_by}</p>
                                                <a 
                                                    href="#/" 
                                                    className="venobox preview-link"  
                                                    data-toggle="modal" 
                                                    data-target="#galeryclick" 
                                                    title="App 1"
                                                    onClick={() => setGallery(item)}>
                                                    <i className="bx bx-plus" /></a>
                                            </div>
                                        </>
                                    )}
                                    {parseInt(type) === 2 && (
                                        <>                                          
                                            <iframe height="200" src={item.video_url || null}/>
                                            <div className="portfolio-info">
                                                <h4>{item.title}</h4>
                                                <p><FormattedMessage id="component.text.created-by"/>: {item.created_by}</p>
                                            </div>
                                        </>
                                    )}
                                </div>
                            )
                            : !notFound ? null :  <FormattedMessage id="component.text.no-records"/>
                            }
                        </div>
                    </div>
                </section>
            </main>
            {Object.keys(gallery).length > 0 && (
                <div className="modal fade" id="galeryclick" tabIndex={-1} aria-labelledby="galeryclickLabel" aria-hidden="true" style={{zIndex: '100000'}}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div>
                                    <img 
                                        src={getImage(gallery.image)}
                                        alt="article" 
                                    />
                                    <div className="content">
                                        <h1>{gallery.title}</h1>
                                        {Parser(gallery.content)}
                                        
                                        <p>{helpers.formatDate(gallery.created_at)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
