import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import { SchoolManagement } from '../services';
import { Helpers, Loading } from '../components';
import Parser from 'html-react-parser';
require('dotenv').config();

const school_management = new SchoolManagement();
export default function SchoolManagements(props) {
    
    const helpers = new Helpers();
    const intl = useIntl();
    const locale = helpers.changesLocale(intl.locale)

    const [schoolManagementList, setSchoolManagementList] = useState();
    const [notFound, setNotFound] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => getSchoolManagementList())();
    }, []);

    const getSchoolManagementList = () => {
        setLoading(true);
        const params = { page: 1, limit: 10, search: '' };
        school_management.list(params)
        .then(res => {
            console.log(res);            

            setLoading(false);
            setSchoolManagementList(res.result);
        })
        .catch(err => {
            console.error(err);
            if (err.code === 404) {
                setNotFound(true);
            }
            
            setLoading(false);
        })
    }

    const getImage = (fileName) => {
        return `${process.env.REACT_APP_URL_SERVICE}/public/image/school_management/${fileName}`;
    }
    const generateText = (item, key) => {
        return locale === 'id-ID' ? item[key] : item[`en_${key}`];
    }
    return (
        <div>
            <main id="main" data-aos="fade-up">
                <section className="breadcrumbs">
                    <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <h2><FormattedMessage id="menu.school-management"/></h2>
                        <ol>
                        <li><a href="/"><FormattedMessage id="menu.home"/></a></li>
                        <li><FormattedMessage id="menu.school-management"/></li>
                        </ol>
                    </div>
                    </div>
                </section>
                <section id="about" className="inner-page about">
                    <div className="container" data-aos="fade-up">
                        <Loading visible={loading}/>
                        {
                        !loading && schoolManagementList?.length > 0 
                            ? 
                            (
                                <div className="row">
                                    {
                                        schoolManagementList.map((data, i) => (
                                            <div 
                                                className='col-lg-6 py-3'                                                
                                            >
                                                <div className="card h-100">
                                                    <img 
                                                        className="img-thumbnail"
                                                        height={150}
                                                        width={150} 
                                                        src={getImage(data.image)} 
                                                    />
                                                    <div className="card-body">
                                                        <h5 className="card-title">{data.name}</h5>
                                                        <p className="card-text">
                                                            {Parser(generateText(data, 'content'))}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>                                            
                                        ))
                                    }
                                </div>
                            )
                            : !notFound ? null :  <FormattedMessage id="component.text.no-records"/>
                        }
                    </div>
                </section>
            </main>
        </div>
    )
}
