import moment from 'moment';
import * as id from 'moment/locale/id' ;

export default class Helpers {
    constructor() {}

    formatDate = (date, format = 'LLLL', lang = 'id-ID') => {        
        lang = lang === 'id-ID' ? 'id' : 'en';
        moment.locale(lang);

        return moment(date).format(format);
    }

    formatNumber = (number) => {
        const num = new Intl.NumberFormat('id-ID').format(number);
        if (num === "0") {
            return "Free Tickets";
        }
        return `Rp. ${num}`;
    }

    changesLocale = (locale) => {
        if (locale === 'id-id') {
            return 'id-ID'
        }
        return 'en-EN'
    }
}