
import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import { Testimonial } from '../services';
import { Helpers, Loading } from '../components';
import Parser from 'html-react-parser';

const testimonial = new Testimonial();

export default function Testimonials(props) {  
    
    const helpers = new Helpers();
    const intl = useIntl();
    const locale = helpers.changesLocale(intl.locale)

    const [testimonialList, setTestimonialList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [notFound, setNotFound] = useState(false);

    useEffect(() => {
        (async () => getTestimonialList())();
    }, []);

    const getTestimonialList = () => {
        setLoading(true);

        testimonial.list({ limit: 100 })
        .then(res => {
            console.log(res);            

            setLoading(false);
            setTestimonialList(res.result);
        })
        .catch(err => {
            console.error(err);
            if (err.code === 404) {
                setNotFound(true);
            }
            
            setLoading(false);
        })
    }
    return (
        <div>
        <main id="main" data-aos="fade-up">
            <section className="breadcrumbs">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <h2><FormattedMessage id="menu.testimonial"/></h2>
                        <ol>
                        <li><a href="/"><FormattedMessage id="menu.home"/></a></li>
                        <li><FormattedMessage id="menu.testimonial"/></li>
                        </ol>
                    </div>
                </div>
            </section>
                <div className="testimonials text-center">
                    <div className="container">
                        <div className="row">
                            {loading && <div className="col-md-12 mt-5"><Loading visible={loading}/></div>}
                            {!loading && testimonialList.length > 0
                            ? testimonialList.map((item, i) => (
                                <div className="col-md-6 col-lg-4">
                                    <div className="card border-light bg-light text-center">
                                        <div className="text-center">

                                            <img 
                                                src="./assets/img/user.png" 
                                                className="img-fluid card-img-top rounded-circle" 
                                                alt="img-testimoni" style={{width:'60px'}}/>
                                        </div>
                                    <div className="card-body blockquote">
                                        <p className="card-text">
                                            {Parser(locale === 'id-ID' ? item.content : item.en_content)}
                                        </p>
                                        <footer className="blockquote-footer"><cite title="Source Title">{item.alumni.name}</cite></footer>
                                    </div>
                                    </div>
                                </div>
                            ))
                            :
                                !notFound ? null :  <tr><td colSpan="6"><FormattedMessage id="component.text.no-records"/></td></tr>                                
                            }
                            

                        </div>
                    </div>
                </div>

            </main>
        </div>
    
    )
}
