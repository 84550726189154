import { LOCALES } from '../constants';
import component from './en-US/component';
import menu from './en-US/menu';
import form from './en-US/form';
import articles from './en-US/articles';
import academic from "./en-US/academic";

export default {
  [LOCALES.ENGLISH]: {
    hello: "Hello",
    ...component,
    ...menu,
    ...form,
    ...articles,
    ...academic,
  },
};
