
import React from 'react';
import { FormattedMessage } from "react-intl";

export default function Contact(props) {
  return (
    <div>
      <main id="main" data-aos="fade-up">
        <section className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <h2>
                <FormattedMessage id="menu.contactus" />
              </h2>
              <ol>
                <li>
                  <a href="/">
                    <FormattedMessage id="menu.home" />
                  </a>
                </li>
                <li>
                  <FormattedMessage id="menu.contactus" />
                </li>
              </ol>
            </div>
          </div>
        </section>
        <section className="inner-page contact">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div className="col-md-12">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.8270816229406!2d106.83818401433301!3d-6.153908395544494!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f5eac0603441%3A0x6debf0bdb57fd860!2sMahatma%20Gandhi%20School!5e0!3m2!1sid!2sid!4v1620401974208!5m2!1sid!2sid"
                  width="100%"
                  height="300px"
                  allowfullscreen=""
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
          <div className="container mt-4" data-aos="fade-up">
            <div className="row" data-aos="fade-up" data-aos-delay={100}>
              <div className="col-lg-6">
                <div className="info-box mb-4">
                  <i className="bx bx-map" />
                  <h3>
                    <FormattedMessage id="component.text.our-address" />
                  </h3>
                  <p>
                    <strong>MGS Kemayoran</strong> <br />
                    J1. Tabing Blok B16, No.3 Kemayoran, Jakarta Pusat.
                    <br />
                    <strong>Fax :</strong> +6221-6542479
                    <br />
                  </p>
                  <br />
                  <p>
                    <strong>MGS Pasar Baru</strong> <br />
                    JI. Pasar Baru Selatan No.10, Jakarta Pusat.
                    <br />
                    <strong>Fax :</strong> +6221-3508963
                    <br />
                    <strong>Email :</strong> mgsjkt@outlook.com
                    <br />
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="info-box  mb-4">
                  <i className="bx bx-envelope" />
                  <h3>
                    <FormattedMessage id="component.text.email-us" />
                  </h3>
                  <p>mgsjkt@outlook.com</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="info-box  mb-4">
                  <i className="bx bx-phone-call" />
                  <h3>
                    <FormattedMessage id="component.text.call-us" />
                  </h3>
                  <p>
                    <strong>MGS Kemayoran</strong> <br />
                    <strong>Phone :</strong> +6221-6542241, 6542480
                    <br />
                  </p>
                  <p>
                    <strong>MGS Pasar Baru</strong> <br />
                    <strong>Phone :</strong> +6221-3508963
                    <br />
                  </p>
                  <p>
                    <strong>For Admission</strong> <br />
                    <strong>Phone :</strong> +6285283247514
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
