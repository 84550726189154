import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import { Teachers } from '../services';
import { Helpers, Loading } from '../components';
import Parser from 'html-react-parser';
require('dotenv').config();

const teachers = new Teachers();
export default function Teacher(props) {
    
    const helpers = new Helpers();
    const intl = useIntl();
    const locale = helpers.changesLocale(intl.locale)

    const [show, setShow]=useState(false)
    const [teacherList, setTeacherList] = useState([]);
    const [teacherLoading, setTeacherLoading] = useState(false);

    const [loading, setLoading] = useState(false);
    const [notFound, setNotFound] = useState(false);

    useEffect(() => {
        (async () => getTeacherList())();
    }, []);

    const getTeacherList = () => {
        setTeacherLoading(true);

        teachers.list({ limit: 100 })
        .then(res => {
            console.log(res);            

            setTeacherLoading(false);
            setTeacherList(res.result);
            setShow(!show);
        })
        .catch(err => {
            console.error(err);
            if (err.code === 404) {
                setNotFound(true);
            }
            
            setTeacherLoading(false);
            setShow(!show);
        })
    }

    const getImage = (fileName, path) => {
        return `${process.env.REACT_APP_URL_SERVICE}/public/image/${path}/${fileName}`;
    }

    const generateText = (item, key) => {
        return locale === 'id-ID' ? item[key] : item[`en_${key}`];
    }
   
    return (
        <div>
            <main id="main" data-aos="fade-up">
                <section className="breadcrumbs">
                    <div className="container">
                        <div className="d-flex justify-content-between align-items-center">
                            <h2><FormattedMessage id="menu.teacher-profile"/></h2>
                                <ol>
                                    <li><a href="/"><FormattedMessage id="menu.home"/></a></li>
                                    <li><FormattedMessage id="menu.teacher-profile"/></li>
                                </ol>
                        </div>
                    </div>
                </section>
                  {/* ======= Team Section ======= */}
                  <section id="team" className="team">
                    <div className="container" data-aos="fade-up">
                        {show ? (
                            <div className="row">
                                <Loading visible={teacherLoading}/>
                                {!teacherLoading && teacherList.length > 0
                                    ? teacherList.map(item => (
                                        <div className="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay={100}>
                                            <div className="member">
                                            <div className="member-img">
                                                <img src={getImage(item.image, 'teachers')} className="img-fluid" alt />
                                            
                                            </div>
                                            <div className="member-info">
                                                <h4>{item.name}</h4>
                                                <span>{generateText(item, 'position')}</span>
                                            </div>
                                            </div>
                                        </div>
                                    ))
                                    : !notFound ? null :  <FormattedMessage id="component.text.no-records"/>
                                }                       
                            </div>
                        ) :
                        (
                        <div className="row">
                            <div className="col-md-12 col-sm-12 mb-6" >
                                <div className="text-center">
                                <img src="assets/img/under.png" className="responsive-image" style={{width:'500px'}}/>
                                <h4 style={{color:'grey', fontFamily: "Poppins"}}>"<FormattedMessage id="component.under"/>"</h4>
                                </div>
                             </div>
                         </div>
                        )
                    }
                </div>
            </section>{/* End Team Section */}
                
            </main>
        </div>
    )
}
