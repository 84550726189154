export default {
    'menu.about': 'About Us',
    'menu.event': 'Events',
    'menu.article': 'Articles',
    'menu.news': 'School News',
    'menu.gallery': 'Galeries',
    'menu.home': 'Home',
    'menu.contact': 'Contact',
    'menu.contactus': 'Contact Us',
    'menu.language': 'Language',
    'menu.testimoni': 'Testimonials',
    'menu.testimonial': 'Alumni Testimonials',
    'menu.tk': 'Kindergarten',
    'menu.sd': 'Elementary School',
    'menu.smp': 'Junior High School',
    'menu.sma': 'High School',
    'menu.list-alumni': 'List Alumni',
    'menu.information': 'Information',
    'menu.registration': 'Registration',
    'menu.vacancies': 'Vacancies',
    'menu.photo': 'Photo',
    'menu.video': 'Video',
    'menu.portal': 'Portal',
    'menu.cbt': 'CBT',
    'menu.e-raport': 'E-Raport',
    'menu.academic': 'Academic',
    'menu.non-academic': 'Non Academic',
    'menu.teacher-profile': 'Teacher Profile',
    'menu.tour': 'Virtual School Tour',
    'menu.photo': 'Photo',
    'menu.our-photo': 'Our Photo',
    'menu.video': 'Video',
    'menu.our-video': 'Our Video',
    'menu.vission': 'Vision',
    'menu.mission': 'Mission',
    'menu.principal': 'Principal',
    'menu.chairman': 'Chairman',
    'menu.support': 'Support',
    'menu.calendar': 'Calendar',
    'menu.activity-calendar': 'School Activity Calendar',
    'menu.faq': 'Faq',
    'menu.inquiry': 'Inquiry',
    'menu.fees': 'Fees',
    'menu.apply': 'Apply',
    'menu.terms': 'Terms and Conditional',
    'menu.admission': 'Admission Guidelines',
    'menu.important': 'Important Dates',
    'menu.child': 'Child Safeguarding',
    'menu.policy': 'School Policy',
    'menu.license': 'School License',
    'menu.announcement': 'Announcement',
    'menu.school-management': 'School Management',
}