
import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import { Announcement } from '../services';
import { Helpers, Loading } from '../components';
import Parser from 'html-react-parser';

const announcement = new Announcement();
export default function Announcements(props) {      
    const helpers = new Helpers();
    const intl = useIntl();
    const locale = helpers.changesLocale(intl.locale)

    const [announcementList, setAnnouncementList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [notFound, setNotFound] = useState(false);

    useEffect(() => {
        (async () => getAnnouncementList())();
    }, []);

    const getAnnouncementList = () => {
        setLoading(true);

        announcement.list({ limit: 100 })
        .then(res => {
            console.log(res);            

            setLoading(false);
            setAnnouncementList(res.result);
        })
        .catch(err => {
            console.error(err);
            if (err.code === 404) {
                setNotFound(true);
            }
            
            setLoading(false);
        })
    }
    
    const generateText = (item, key) => {
        return locale === 'id-ID' ? item[key] : item[`en_${key}`];
    }
    
    return (
        <div>
             <main id="main" data-aos="fade-up">
                <section className="breadcrumbs">
                    <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <h2><FormattedMessage id="menu.announcement"/></h2>
                        <ol>
                        <li><a href="/"><FormattedMessage id="menu.home"/></a></li>
                        <li><FormattedMessage id="menu.announcement"/></li>
                        </ol>
                    </div>
                    </div>
                </section>
                <section id="list-alumni" className="list-alumni">
                   <div className="container" data-aos="fade-up">
                   <Loading visible={loading}/>
                    {!loading && announcementList.length > 0
                    ? announcementList.map((item, i) => (
                        <div className="card mb-3">
                            <div className="row no-gutters">
                                <div className="col-md-12">
                                    <div className="card-body">
                                        <h3 className="card-title mb-3 mt-3">{generateText(item, 'title')}</h3>                                        
                                        {Parser(generateText(item, 'content'))}
                                        <p className="card-text"><small className="text-muted"><FormattedMessage id="component.text.last-update"/> {helpers.formatDate(item.created_at, 'LLL', locale)}</small></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                    : !notFound ? null :  <FormattedMessage id="component.text.no-records"/>
                    }
                   </div>
                </section>
            </main>
        </div>
    )
}
