
import React, { useState, useEffect } from 'react';
import { FormattedMessage } from "react-intl";
import { Vacancies } from '../services';
import { Helpers, Loading } from '../components';
import Parser from 'html-react-parser';

const helpers = new Helpers();
const apply = new Vacancies();

export default function Apply(props) {  
    const [applyList, setApplyList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [notFound, setNotFound] = useState(false);

    useEffect(() => {
        (async () => getApplyList())();
    }, []);

    const getApplyList = () => {
        setLoading(true);

        apply.list({ limit: 100 })
        .then(res => {
            console.log(res);            

            setLoading(false);
            setApplyList(res.result);
        })
        .catch(err => {
            console.error(err);
            if (err.code === 404) {
                setNotFound(true);
            }
            
            setLoading(false);
        })
    }

    return (
        <div>
             <main id="main" data-aos="fade-up">
                <section className="breadcrumbs">
                    <div className="container">
                        <div className="d-flex justify-content-between align-items-center">
                            <h2><FormattedMessage id="menu.apply"/></h2>
                            <ol>
                                <li><a href="/"><FormattedMessage id="menu.home"/></a></li>
                                <li><FormattedMessage id="menu.apply"/></li>
                            </ol>
                        </div>
                    </div>
                </section>
                {/* ======= Frequently Asked Questions Section ======= */}
                <section id="faq" className="faq">
                    <div className="container" data-aos="fade-up">
                       
                        <ul className="faq-list" data-aos="fade-up" data-aos-delay={100}>
                        <Loading visible={loading}/>
                        {!loading && applyList.length > 0
                        ? applyList.map((item, i) => (
                            <li>
                                <a data-toggle="collapse" className="collapsed" href={"#faq"+(item.id)}>{item.title}  <i className="icofont-simple-up" /></a>
                                <div id={"faq"+(item.id)} className="collapse" data-parent=".faq-list">
                                    <p>
                                        {Parser(item.content)}
                                    </p>
                                </div>
                            </li>
                                ))
                                : !notFound ? null :  <FormattedMessage id="component.text.no-records"/>
                                }
                        </ul>
                    </div>
                </section>
            </main>
        </div>
    )
}
