import { FormattedMessage } from "react-intl";

export const LOCALES = {
  ENGLISH: "en-us",
  INDONESIAN: "id-id",
};

export const StaticContent = {
  TK: [
    {
      title: <FormattedMessage id="TK.academic-one.title" />,
      content: "academic-one",
      created_at: "2022-04-24T03:20:50.049Z",
      created_by: "ADMIN",
      file: "1_academic.jpeg",
      type: "image",
    },
    {
      title: <FormattedMessage id="TK.academic-two.title" />,
      content: "academic-two",
      created_at: "2022-04-24T03:20:50.049Z",
      created_by: "ADMIN",
      file: "2_academic.jpg",
      type: "image",
    },
  ],
  SD: [],
  SMP: [],
  SMA: [],
};
