import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import { VisionMission } from '../services';
import { Helpers, Loading } from '../components';
import Parser from 'html-react-parser';
require('dotenv').config();

const vissions = new VisionMission();
export default function Vission(props) {
    const helpers = new Helpers();
    const intl = useIntl();
    const locale = helpers.changesLocale(intl.locale)

    const [vission, setVission] = useState();
    const [notFound, setNotFound] = useState(false);
    const [loading, setLoading] = useState(false);
    

    useEffect(() => {
        (async () => getVissionInfo())();
    }, []);

    const getVissionInfo = () => {
        setLoading(true);

        vissions.getVisionOrMission('VISION', locale)
        .then(res => {
            console.log(res);            

            setLoading(false);
            setVission(res.result);
        })
        .catch(err => {
            console.error(err);
            if (err.code === 404) {
                setNotFound(true);
            }
            
            setLoading(false);
        })
    }

    const getImage = (fileName) => {
        return `${process.env.REACT_APP_URL_SERVICE}/public/image/vision_mission/${fileName}`;
    }
    return (
        <div>
            <main id="main" data-aos="fade-up">
                <section className="breadcrumbs">
                    <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <h2><FormattedMessage id="menu.vission"/></h2>
                        <ol>
                        <li><a href="/"><FormattedMessage id="menu.home"/></a></li>
                        <li><FormattedMessage id="menu.vission"/></li>
                        </ol>
                    </div>
                    </div>
                </section>
                <section id="about" className="inner-page about">
                    <div className="container" data-aos="fade-up">
                        <Loading visible={loading}/>
                        {
                        !loading && vission 
                            ? 
                            (
                                <div className="row">

                                    <div className="col-lg-6" data-aos="zoom-out" data-aos-delay={100}>
                                        <img src={getImage(vission.image)} className="img-fluid" alt="img" />
                                    </div>
                                    <div className="col-lg-6 pt-4 pt-lg-0 content d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay={100}>
                                        <div>
                                            {Parser(vission.content)}
                                        </div>
                                    </div>
                                </div>
                            )
                            : !notFound ? null :  <FormattedMessage id="component.text.no-records"/>
                        }
                    </div>
                </section>
            </main>
        </div>
    )
}
