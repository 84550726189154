import { LOCALES } from '../constants';
import component from './id-ID/component';
import menu from './id-ID/menu';
import form from './id-ID/form';
import articles from './id-ID/articles';
import academic from "./id-ID/academic";

export default {
  [LOCALES.INDONESIAN]: {
    hello: "Hello",
    ...component,
    ...menu,
    ...form,
    ...articles,
    ...academic,
  },
};
