import '../App.css';
import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import { Articles } from '../services';
import Parser from 'html-react-parser';
import { Helpers, Loading } from '../components';
import { StaticContent } from '../i18nProvider/constants';
require('dotenv').config();

const articles = new Articles();
const helpers = new Helpers();

export default function Article(props) {  
    
    const helpers = new Helpers();
    const intl = useIntl();
    const locale = helpers.changesLocale(intl.locale)

    const [articleList, setArticleList] = useState([]);
    const [article, setArticle] = useState({});
    const [loading, setLoading] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [level, setLevel] = useState(props.location.search.replace('?level=', ''));
    const [staticArticleList, setStaticArticleList] = useState([]);

    useEffect(() => {
        (async () => getArticleList())();
    }, []);

    const getArticleList = () => {
        setLoading(true);

        // setStaticArticleList(StaticContent[level]);
        // setLoading(false);
        // if (StaticContent[level].length < 1) setNotFound(true)
        // else setNotFound(false)

        // # Dont remove! this code for the future, perhaps.
        articles.list({ limit: 100, level })
        .then(res => {
            console.log(res);            

            setLoading(false);
            setArticleList(res.result);
        })
        .catch(err => {
            console.error(err);
            if (err.code === 404) {
                setNotFound(true);
            }
            
            setLoading(false);
        })
    }

    const getImage = (fileName) => {
        return `${process.env.REACT_APP_URL_SERVICE}/public/image/articles/${fileName}`;
    }

    const generateText = (item, key) => {
        return locale === 'id-ID' ? item[key] : item[`en_${key}`];
    }

    const staticContent = (item) => {
        return (
            <div className="col-md-4 col-lg-6 mb-6" >
                <div className="card mb-6" data-aos="fade-up">                    
                    <div className="content">
                        <h3>{generateText(item, 'title')}</h3>                        
                        <img className='img-fluid' src={getImage(item.image)} alt={item.title} style={{ height: 250}} />                         
                    </div>
                    <div className="auther">                        
                        <button 
                            onClick={() => setArticle(item)}
                            className="btn btn-secondary" 
                            data-toggle="modal" 
                            data-target="#articleclick">
                            <i className="icofont-eye"></i> <FormattedMessage id="component.text.detail"/>
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div>
             <main id="main" data-aos="fade-up">
                <section className="breadcrumbs">
                    <div className="container">
                        <div className="d-flex justify-content-between align-items-center">
                            <h2><FormattedMessage id="menu.news"/></h2>
                            <ol>
                                <li><a href="/"><FormattedMessage id="menu.home"/></a></li>
                                <li><FormattedMessage id="menu.news"/></li>
                            </ol>
                        </div>
                    </div>
                </section>
                <div className="container mt-5 mb-4" >
                   <div className="row">
                        <Loading visible={loading}/>
                        {notFound 
                            ? <FormattedMessage id="component.text.no-records" />
                            : articleList.map(staticContent)
                        }
                   </div>
                </div>

            </main>
            {/* Modal */}
            {Object.keys(article).length > 0 && (
                <div className="modal fade" id="articleclick" tabIndex={-1} aria-labelledby="articleclickLabel" aria-hidden="true" style={{zIndex: '100000'}}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                <img src={getImage(article.image)} alt={article.title} style={{ alignSelf: 'center'}} />                                
                                <div className="content">
                                    {
                                        Parser(generateText(article, 'content'))
                                    }
                                    
                                </div>
                                <div className="auther">
                                    <div className="avatar">
                                        <div className="i-d">
                                                <div className="description">
                                                    <h3>{article.created_by}</h3>
                                                    <p>{helpers.formatDate(article.created_at, 'LLL', locale)}</p>
                                                </div>
                                        </div>
                                    </div>
                                    <div className="action" />
                                </div>
                            </div>
                        </div>
                    
                        </div>
                    </div>
                </div>
            )}

        </div>
    )
}
