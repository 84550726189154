import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import { AcademicCalendar } from '../services';
import Parser from 'html-react-parser';
import { Helpers } from '../components';
import { Loading } from '../components';
require('dotenv').config();

const academicCalendar = new AcademicCalendar();

export default function Calendar(props) {  
    const helpers = new Helpers();
    const intl = useIntl();
    const locale = helpers.changesLocale(intl.locale)

    const [academicCalendarList, setAcademicCalendarList] = useState([]);
    const [academicCalendarLoading, setAcademicCalendarLoading] = useState(false);
    const [gallery, setGallery] = useState({});
    const [loading, setLoading] = useState(false);
    const [notFound, setNotFound] = useState(false);

    useEffect(() => {
        (async () => getAcademicCalendarList())();
    }, []);
    
    const getAcademicCalendarList = () => {
        setAcademicCalendarLoading(true);

        academicCalendar.list({ limit: 100, type: 1 })
        .then(res => {
            console.log(res);            

            setAcademicCalendarLoading(false);
            setAcademicCalendarList(res.result);
        })
        .catch(err => {
            console.error(err);
            if (err.code === 404) {
                setNotFound(true);
            }
            
            setAcademicCalendarLoading(false);
        })
    }
    
    const getImage = (fileName) => {
        return `${process.env.REACT_APP_URL_SERVICE}/public/image/academic_calendar/${fileName}`;
    }
    const generateText = (item, key) => {
        return locale === 'id-ID' ? item[key] : item[`en_${key}`];
    }

    return (
        <div>            
            <main id="main" data-aos="fade-up">
                <section className="breadcrumbs">
                    <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <h2><FormattedMessage id="component.text.calendar"/></h2>
                        <ol>
                        <li><a href="/"><FormattedMessage id="menu.home"/></a></li>
                        <li><FormattedMessage id="component.text.calendar"/></li>
                        </ol>
                    </div>
                    </div>
                </section>
                  
                <section id="portfolio" className="portfolio">
                    <div className="container" data-aos="fade-up">
                        <div className="row" data-aos="fade-up" data-aos-delay={200}>
                            <Loading visible={academicCalendarLoading}/>
                            {!academicCalendarLoading && academicCalendarList.length > 0 
                            ? academicCalendarList.map(item => 
                                <div className="col-lg-6 col-md-6 portfolio-item" >
                                    <img 
                                        src={getImage(item.image)}  
                                        className="img-fluid" 
                                        alt="img" 
                                        key={item.id} />

                                        <div className="portfolio-info">
                                            <h4>{generateText(item, 'title')}</h4>
                                            <p><FormattedMessage id="component.text.created-by"/>: {item.created_by}</p>
                                            <a 
                                                href="#/" 
                                                className="venobox preview-link"  
                                                data-toggle="modal" 
                                                data-target="#calendarclick" 
                                                title="App 1"
                                                onClick={() => { setGallery(item)}}>
                                                <i className="bx bx-plus" /></a>
                                        </div>
                                </div>
                            )
                            : !notFound ? null :  <FormattedMessage id="component.text.no-records"/>
                            }
                        </div>
                    </div>
                </section>
            </main>
                 {Object.keys(gallery).length > 0 && (
                <div className="modal fade" id="calendarclick" tabIndex={-1} aria-labelledby="calendarclickLabel" aria-hidden="true" style={{zIndex: '100000'}}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div>
                                    <img 
                                        src={getImage(gallery.image)}
                                        alt="article" 
                                    />
                                    <div className="content">
                                        <h1>{generateText(gallery, 'title')}</h1>
                                        
                                        <p>{helpers.formatDate(gallery.created_at, 'LLLL', locale)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
