export default {
  "TK.academic-one.title": "TK PASAR BARU",
  "TK.academic-one": `<p align="center"><strong>Profil</strong></p>
                          <p>Mahatma Gandhi School didirikan pada tahun 1950 dengan tujuan untuk memberikan layanan pendidikan dan dukungan kepada anak-anak bangsa sehingga mereka mempunyai kesempatan yang berharga untuk mengembangkan bakat dan kemampuannya sejak dini demi mencapai kesuksesan di masa yang akan datang.</p>
                          <p>Sekolah ini memiliki tradisi lebih dari setengah abad dalam memberikan pendidikan berkualitas secara nasional maupun global melalui komitmennya yang sahih dan terpercaya.</p>
                          <p>Untuk pemenuhan layanan pendidikan sejak dini maka Mahatma Gandhi School membuka kesempatan kepada anak usia dini untuk mengikuti pendidikan formal di sekolah.</p>
                          <p>Saat ini Mahatma Gandhi School memiliki dua cabang Pendidikan Anak Usia Dini, yaitu TK Mahatma Gandhi Pasar Baru dan TK Mahatma Gandhi Kemayoran.</p>
                          <p>&nbsp;</p>
                          <p align="center"><strong>Kurikulum</strong></p>
                          <p>TK Mahatma Gandhi school saat ini menggunakan kurikulum pendidikan nasional yaitu K-13 dan Program Bahasa Inggris.</p>
                          <p>Kurikulum K-13 yang dikembangkan di TK Mahatma Gandhi bertujuan untuk mengembangkan kompetensi anak sesuai dengan Standar Tingkat Pencapaian Perkembangan Anak (STPPA), seperti Nilai Moral dan Agama, Motorik Halus dan Kasar, Kognitif, Bahasa, Sosial Emosional dan Seni.</p>
                          <p>Untuk meningkatkan kompetensi anak dalam menghadapi tantangan global abad ke-21, TK Mahatma Gandhi mengembangkan program Bahasa Inggris.</p>
                          <p>Selain itu, Bahasa Inggris juga digunakan sebagai bahasa pengantar di lingkungan sekolah agar anak-anak terbiasa berkomunikasi dalam Bahasa Inggris.</p>
                          <p>&nbsp;</p>
                          <p align="center"><strong>Program </strong><strong>B</strong><strong>ahasa Inggris</strong></p>
                          <p><strong>A. Overall Development</strong></p>
                          <ul>
                          <li>Conversation / Thematic</li>
                          <li>Reading (letters, sound / phonics, words, sentences)</li>
                          <li>Rhymes / Songs</li>
                          <li>Show and tell</li>
                          </ul>
                          <p><strong>B. Number Concept / Math</strong></p>
                          <ul>
                          <li>Sorting</li>
                          <li>Counting</li>
                          <li>Vocabulary</li>
                          </ul>
                          <p><strong>C. Writing (Motoric Development)</strong></p>
                          <ul>
                          <li>Colouring</li>
                          <li>Spelling / Dictation</li>
                          <li>Tracing</li>
                          <li>Pattern</li>
                          <li>Handwriting</li>
                          </ul>
                          <p><strong>D. Creative Expression</strong></p>
                          <ul>
                          <li>Drawing</li>
                          <li>Finger Painting</li>
                          <li>Craft</li>
                          </ul>
                          <p>&nbsp;</p>
                          <p align="center"><strong>Kegiatan </strong><strong>Ekstrakurikuler</strong></p>
                          <p>&nbsp;</p>
                          <p>TK Mahatma Gandhi juga memiliki kegiatan esktrakurikuler yang bertujuan untuk mengembangkan potensi diri anak sesuai dengan minat dan bakatnya masing-masing.</p>
                          <p>Adapun Kegiatan Ekstrakulikuler tersebut antara lain :</p>
                          <ul>
                          <li>Menari</li>
                          <li>Menyanyi dan bermain alat musik</li>
                          <li>Kegiatan Olahraga</li>
                          <li>Berenang</li>
                          <li>Bahasa Mandarin</li>
                          </ul>
                          <p>&nbsp;</p>
                          <p><strong>Persyaratan dan tata cara pendaftaran mahasiswa baru :</strong></p>
                          <p>● Isi formulir pendaftaran</p>
                          <p>● Pas foto ukuran paspor (3x4) = 3 lembar dengan latar belakang merah</p>
                          <p>● Fotokopi Akta Kelahiran</p>
                          <p>● Fotokopi Kartu Keluarga</p>
                          <p>&nbsp;</p>
                          <p><strong>Persyaratan Usia</strong></p>
                          <p>● Kelompok Bermain (KB): 3-4 tahun</p>
                          <p>● Kelompok A (TK.A) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: 4-5 tahun</p>
                          <p>● Kelompok B (TK.B)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : 5-6 tahun</p>
                          <p>&nbsp;</p>
                          <p><strong>Contact Person</strong></p>
                          <p><strong>TK MGS Pasar Baru</strong></p>
                          <ul>
                          <li>Ms Pooja : 0817-808-270</li>
                          <li>Ms Cicil&nbsp;&nbsp;&nbsp; : 0857-8191-643 5</li>
                          </ul>
                          <p>&nbsp;</p>
                          <p><strong>TK MGS Kemayoran</strong></p>
                          <ul>
                          <li>MS Edith : 0815-1929-0169</li>
                          <li>Ms Selvi&nbsp; : 0852-1616-5982</li>
                          </ul>`,
  "TK.academic-two.title": "TK KEMAYORAN",
  "TK.academic-two": `<p align="center"><strong>Profil</strong></p>
                          <p>Mahatma Gandhi School didirikan pada tahun 1950 dengan tujuan untuk memberikan layanan pendidikan dan dukungan kepada anak-anak bangsa sehingga mereka mempunyai kesempatan yang berharga untuk mengembangkan bakat dan kemampuannya sejak dini demi mencapai kesuksesan di masa yang akan datang.</p>
                          <p>Sekolah ini memiliki tradisi lebih dari setengah abad dalam memberikan pendidikan berkualitas secara nasional maupun global melalui komitmennya yang sahih dan terpercaya.</p>
                          <p>Untuk pemenuhan layanan pendidikan sejak dini maka Mahatma Gandhi School membuka kesempatan kepada anak usia dini untuk mengikuti pendidikan formal di sekolah.</p>
                          <p>Saat ini Mahatma Gandhi School memiliki dua cabang Pendidikan Anak Usia Dini, yaitu TK Mahatma Gandhi Pasar Baru dan TK Mahatma Gandhi Kemayoran.</p>
                          <p>&nbsp;</p>
                          <p align="center"><strong>Kurikulum</strong></p>
                          <p>TK Mahatma Gandhi school saat ini menggunakan kurikulum pendidikan nasional yaitu K-13 dan Program Bahasa Inggris.</p>
                          <p>Kurikulum K-13 yang dikembangkan di TK Mahatma Gandhi bertujuan untuk mengembangkan kompetensi anak sesuai dengan Standar Tingkat Pencapaian Perkembangan Anak (STPPA), seperti Nilai Moral dan Agama, Motorik Halus dan Kasar, Kognitif, Bahasa, Sosial Emosional dan Seni.</p>
                          <p>Untuk meningkatkan kompetensi anak dalam menghadapi tantangan global abad ke-21, TK Mahatma Gandhi mengembangkan program Bahasa Inggris.</p>
                          <p>Selain itu, Bahasa Inggris juga digunakan sebagai bahasa pengantar di lingkungan sekolah agar anak-anak terbiasa berkomunikasi dalam Bahasa Inggris.</p>
                          <p>&nbsp;</p>
                          <p align="center"><strong>Program </strong><strong>B</strong><strong>ahasa Inggris</strong></p>
                          <p><strong>A. Overall Development</strong></p>
                          <ul>
                          <li>Conversation / Thematic</li>
                          <li>Reading (letters, sound / phonics, words, sentences)</li>
                          <li>Rhymes / Songs</li>
                          <li>Show and tell</li>
                          </ul>
                          <p><strong>B. Number Concept / Math</strong></p>
                          <ul>
                          <li>Sorting</li>
                          <li>Counting</li>
                          <li>Vocabulary</li>
                          </ul>
                          <p><strong>C. Writing (Motoric Development)</strong></p>
                          <ul>
                          <li>Colouring</li>
                          <li>Spelling / Dictation</li>
                          <li>Tracing</li>
                          <li>Pattern</li>
                          <li>Handwriting</li>
                          </ul>
                          <p><strong>D. Creative Expression</strong></p>
                          <ul>
                          <li>Drawing</li>
                          <li>Finger Painting</li>
                          <li>Craft</li>
                          </ul>
                          <p>&nbsp;</p>
                          <p align="center"><strong>Kegiatan </strong><strong>Ekstrakurikuler</strong></p>
                          <p>&nbsp;</p>
                          <p>TK Mahatma Gandhi juga memiliki kegiatan esktrakurikuler yang bertujuan untuk mengembangkan potensi diri anak sesuai dengan minat dan bakatnya masing-masing.</p>
                          <p>Adapun Kegiatan Ekstrakulikuler tersebut antara lain :</p>
                          <ul>
                          <li>Menari</li>
                          <li>Menyanyi dan bermain alat musik</li>
                          <li>Kegiatan Olahraga</li>
                          <li>Berenang</li>
                          <li>Bahasa Mandarin</li>
                          </ul>
                          <p>&nbsp;</p>
                          <p><strong>Persyaratan dan tata cara pendaftaran mahasiswa baru :</strong></p>
                          <p>● Isi formulir pendaftaran</p>
                          <p>● Pas foto ukuran paspor (3x4) = 3 lembar dengan latar belakang merah</p>
                          <p>● Fotokopi Akta Kelahiran</p>
                          <p>● Fotokopi Kartu Keluarga</p>
                          <p>&nbsp;</p>
                          <p><strong>Persyaratan Usia</strong></p>
                          <p>● Kelompok Bermain (KB): 3-4 tahun</p>
                          <p>● Kelompok A (TK.A) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: 4-5 tahun</p>
                          <p>● Kelompok B (TK.B)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : 5-6 tahun</p>
                          <p>&nbsp;</p>
                          <p><strong>Contact Person</strong></p>
                          <p><strong>TK MGS Pasar Baru</strong></p>
                          <ul>
                          <li>Ms Pooja : 0817-808-270</li>
                          <li>Ms Cicil&nbsp;&nbsp;&nbsp; : 0857-8191-643 5</li>
                          </ul>
                          <p>&nbsp;</p>
                          <p><strong>TK MGS Kemayoran</strong></p>
                          <ul>
                          <li>MS Edith : 0815-1929-0169</li>
                          <li>Ms Selvi&nbsp; : 0852-1616-5982</li>
                          </ul>`,
};
