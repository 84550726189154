import { FormattedMessage } from "react-intl";

export const LOCALES = {
  ENGLISH: 'en-us',
  INDONESIAN: 'id-id',
};

export const StaticContent = {
  'TK': [
    {
      title: <FormattedMessage id="TK.articles-one.title"/>,
      content: 'articles-one',
      created_at: "2022-04-24T03:20:50.049Z",
      created_by: "ADMIN",
      file: "article-one.jpg",
      type: "image",
    },
    {
        title: <FormattedMessage id="TK.articles-two.title"/>,
        content: 'articles-two',
        created_at: "2022-04-24T03:20:50.049Z",
        created_by: "ADMIN",
        file: "article-two.jpg",
        type: "image",
    },            
    {
        title: <FormattedMessage id="TK.articles-three.title"/>,
        content: 'articles-three',
        created_at: "2022-04-24T03:20:50.049Z",
        created_by: "ADMIN",
        file: "article-three.png",
        type: "image",
    },           
    {
        title: <FormattedMessage id="TK.articles-four.title"/>,
        content: 'articles-four',
        created_at: "2022-04-24T03:20:50.049Z",
        created_by: "ADMIN",
        file: "https://www.youtube.com/embed/79ypFQD8z_I",
        type: "video",
    },           
    {
        title: <FormattedMessage id="TK.articles-five.title"/>,
        content: 'articles-five',
        created_at: "2022-04-24T03:20:50.049Z",
        created_by: "ADMIN",
        file: "https://www.youtube.com/embed/LTqdfikIJ6w",
        type: "video",
    },         
    {
        title: <FormattedMessage id="TK.articles-six.title"/>,
        content: 'articles-six',
        created_at: "2022-04-24T03:20:50.049Z",
        created_by: "ADMIN",
        file: "https://www.youtube.com/embed/021Cr0jmsTI",
        type: "video",
    }
  ],
  'SD': [
    {
      title: <FormattedMessage id="SD.articles-one.title"/>,
      content: 'articles-one',
      created_at: "2022-04-24T03:20:50.049Z",
      created_by: "ADMIN",
      file: "article-one.png",
      type: "image",
    },
    {
      title: <FormattedMessage id="SD.articles-two.title"/>,
      content: 'articles-two',
      created_at: "2021-04-21T03:20:50.049Z",
      created_by: "ADMIN",
      file: "article-two.png",
      type: "image",
    },
    {
      title: <FormattedMessage id="SD.articles-three.title"/>,
      content: 'articles-three',
      created_at: "2021-02-04T03:20:50.049Z",
      created_by: "ADMIN",
      file: "article-three.png",
      type: "image",
    },
    {
      title: <FormattedMessage id="SD.articles-four.title"/>,
      content: 'articles-four',
      created_at: "2021-08-12T03:20:50.049Z",
      created_by: "ADMIN",
      file: "article-four.png",
      type: "image",
    },
  ],
  'SMP': [    
    {
      title: <FormattedMessage id="SMP.articles-one.title"/>,
      content: 'articles-one',
      created_at: "2022-10-07T03:20:50.049Z",
      created_by: "ADMIN",
      file: "article-one.jpeg",
      type: "image",
    },
  ],
  'SMA': []
}