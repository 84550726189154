export default {
  "TK.academic-one.title": "KINDGARTEN PASAR BARU",
  "TK.academic-one": `<p align="center"><strong>Profile</strong></p>
                        <p>&nbsp;</p>
                        <p>Mahatma Gandhi School was founded in 1950 in Jakarta to help the young generation develop their talents, skills and knowledge from an early age in order to be more successful in the future.</p>
                        <p>&nbsp;</p>
                        <p>This school has a tradition of more than a half century in providing a quality education system nationally and globally through its valid and trusted commitment.</p>
                        <p>&nbsp;</p>
                        <p>To serve early childhood children, Mahatma Gandhi School started preschool level, TK Mahatma Gandhi Pasar Baru and TK Mahatma Gandhi Kemayoran.</p>
                        <p align="center"><strong>Curriculum</strong></p>
                        <p>&nbsp;</p>
                        <p>Our curriculum consists of two categories, Curriculum 2013 (K-13) and English program.</p>
                        <p>Curriculum K-13 helps to improve children&rsquo;s competences in accordance with the Standard of Child Development Achievement Level (STPPA), such as moral and religious values, fine and gross motor skills, cognitive, languages skills, social emotional and art.</p>
                        <p>To prepare our children to face global challenges in the 21<sup>st</sup> century, we designed English programs for preschool level.</p>
                        <p>We are also encouraging our students to speak in English in the school area as a daily habit.</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p align="center"><strong>The English program includes:</strong></p>
                        <ol start="1">
                        <li><strong>Overall Development</strong></li>
                        </ol>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Conversation / Thematic</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Reading (letters, sound / phonics, words, sentences)</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Rhymes / Songs</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Show and tell</p>
                        <p>&nbsp;</p>
                        <ol start="2">
                        <li><strong>Number Concept / Math</strong></li>
                        </ol>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sorting</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Counting</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Vocabulary</p>
                        <p>&nbsp;</p>
                        <ol start="3">
                        <li><strong>Writing (Motoric Development)</strong></li>
                        </ol>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Coloring</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Spelling / Dictation</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Tracing</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Pattern</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Handwriting</p>
                        <p>&nbsp;</p>
                        <ol start="4">
                        <li><strong>Creative Expression</strong></li>
                        </ol>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Drawing</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Finger painting</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Craft</p>
                        <p>&nbsp;</p>
                        <p>We also encourage our students to speak in English as a daily habit.</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p align="center"><strong>Extracurricular Activities</strong></p>
                        <p>&nbsp;</p>
                        <p>TK Mahatma Gandhi has extracurricular activities to develop children's potential according to their respective interests and talents.</p>
                        <p>&nbsp;</p>
                        <p><strong>The extracurricular activities includes:</strong></p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Dancing</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Music (singing and playing musical instruments)</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Mandarin</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Physical Training</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Swimming</p>
                        <p>&nbsp;</p>
                        <p><strong>Requirements and procedure of registrations of the new students :</strong></p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Fill in the registration form</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Passport size photo ( 3x4 ) = 3 pcs with red background</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Copy of Birth certificate</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Copy of Family Card&nbsp;</p>
                        <p>&nbsp;</p>
                        <p><strong>Age Eligibility</strong></p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Play Group (KB) : 3-4 years old</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Kindergarten 1 (TK.A) : 4-5 years old</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Kindergarten 2 (TK.B) : 5-6 years old</p>
                        <p><strong>&nbsp;</strong></p>
                        <p><strong>&nbsp;</strong></p>
                        <p><strong>&nbsp;</strong></p>
                        <p><strong>&nbsp;</strong></p>
                        <p><strong>Contact Person</strong></p>
                        <p><strong>TK MGS Pasar Baru:</strong></p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ms Pooja : 0817-808-270</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ms Cicil&nbsp;&nbsp;&nbsp; : 0857-8191-643 5</p>
                        <p><strong>TK MGS Kemayoran</strong></p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ms Edith : 0815-1929-0169</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ms Selvi&nbsp; : 0852-1616-5982</p>`,
  "TK.academic-two.title": "KINDGARTEN KEMAYORAN",
  "TK.academic-two": `<p align="center"><strong>Profile</strong></p>
                        <p>&nbsp;</p>
                        <p>Mahatma Gandhi School was founded in 1950 in Jakarta to help the young generation develop their talents, skills and knowledge from an early age in order to be more successful in the future.</p>
                        <p>&nbsp;</p>
                        <p>This school has a tradition of more than a half century in providing a quality education system nationally and globally through its valid and trusted commitment.</p>
                        <p>&nbsp;</p>
                        <p>To serve early childhood children, Mahatma Gandhi School started preschool level, TK Mahatma Gandhi Pasar Baru and TK Mahatma Gandhi Kemayoran.</p>
                        <p align="center"><strong>Curriculum</strong></p>
                        <p>&nbsp;</p>
                        <p>Our curriculum consists of two categories, Curriculum 2013 (K-13) and English program.</p>
                        <p>Curriculum K-13 helps to improve children&rsquo;s competences in accordance with the Standard of Child Development Achievement Level (STPPA), such as moral and religious values, fine and gross motor skills, cognitive, languages skills, social emotional and art.</p>
                        <p>To prepare our children to face global challenges in the 21<sup>st</sup> century, we designed English programs for preschool level.</p>
                        <p>We are also encouraging our students to speak in English in the school area as a daily habit.</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p align="center"><strong>The English program includes:</strong></p>
                        <ol start="1">
                        <li><strong>Overall Development</strong></li>
                        </ol>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Conversation / Thematic</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Reading (letters, sound / phonics, words, sentences)</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Rhymes / Songs</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Show and tell</p>
                        <p>&nbsp;</p>
                        <ol start="2">
                        <li><strong>Number Concept / Math</strong></li>
                        </ol>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sorting</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Counting</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Vocabulary</p>
                        <p>&nbsp;</p>
                        <ol start="3">
                        <li><strong>Writing (Motoric Development)</strong></li>
                        </ol>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Coloring</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Spelling / Dictation</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Tracing</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Pattern</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Handwriting</p>
                        <p>&nbsp;</p>
                        <ol start="4">
                        <li><strong>Creative Expression</strong></li>
                        </ol>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Drawing</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Finger painting</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Craft</p>
                        <p>&nbsp;</p>
                        <p>We also encourage our students to speak in English as a daily habit.</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <p align="center"><strong>Extracurricular Activities</strong></p>
                        <p>&nbsp;</p>
                        <p>TK Mahatma Gandhi has extracurricular activities to develop children's potential according to their respective interests and talents.</p>
                        <p>&nbsp;</p>
                        <p><strong>The extracurricular activities includes:</strong></p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Dancing</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Music (singing and playing musical instruments)</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Mandarin</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Physical Training</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Swimming</p>
                        <p>&nbsp;</p>
                        <p><strong>Requirements and procedure of registrations of the new students :</strong></p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Fill in the registration form</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Passport size photo ( 3x4 ) = 3 pcs with red background</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Copy of Birth certificate</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Copy of Family Card&nbsp;</p>
                        <p>&nbsp;</p>
                        <p><strong>Age Eligibility</strong></p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Play Group (KB) : 3-4 years old</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Kindergarten 1 (TK.A) : 4-5 years old</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Kindergarten 2 (TK.B) : 5-6 years old</p>
                        <p><strong>&nbsp;</strong></p>
                        <p><strong>&nbsp;</strong></p>
                        <p><strong>&nbsp;</strong></p>
                        <p><strong>&nbsp;</strong></p>
                        <p><strong>Contact Person</strong></p>
                        <p><strong>TK MGS Pasar Baru:</strong></p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ms Pooja : 0817-808-270</p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ms Cicil&nbsp;&nbsp;&nbsp; : 0857-8191-643 5</p>
                        <p><strong>TK MGS Kemayoran</strong></p>
                        <p>●&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ms Edith : 0815-1929-0169</p>
                        <p>●&nbsp; &nbsp; &nbsp;&nbsp;<span>Ms Selvi&nbsp; : 0852-1616-5982</span></p>
                        <p align="center">&nbsp;</p>`,
};
