export default {
    "TK.articles-one.title": 'Telephone Craft Activity',
    "TK.articles-one":  `<p>This time, our students in TKB Mahatma Gandhi Pasar Baru are learning about communication.</p>
                            <p>There are three types of communication systems: oral communication, written communication and visual communication.</p>
                            <p>In the modern era, people are able to communicate long distance by using a telephone.</p>
                            <p>To learn about telephones, students make a telephone craft using simple materials which are available in the classroom.</p>
                            <p>&nbsp;</p>
                            <p><strong>Materials Needed:</strong></p>
                            <ul>
                            <li>Drawing book</li>
                            <li>Glue</li>
                            <li>Scissor</li>
                            <li>C.D &nbsp;players</li>
                            <li>Asturo paper</li>
                            <li>Straw</li>
                            </ul>
                        `,
    "TK.articles-two.title": 'Making a Raft',     
    "TK.articles-two":  `<p>For kindergarten level, STEAM is the most popular teaching approach. STEAM stands for Science, Technology, Engineering, Art and Mathematics.</p>
                            <p>Our teachers usually use this approach to attract students' interest in daily basis lesson activities.</p>
                            <p>This week, we have fun with STEAM activity by making a simple raft from simple materials around us.</p>
                            <p>This activity lets students learn about traditional transportation used by fishermen like a raft.</p>
                            <p>&nbsp;</p>
                            <p><strong>Materials Needed</strong></p>
                            <ul>
                            <li>Straws</li>
                            <li>Origami paper</li>
                            <li>Glue</li>
                            <li>Scissor</li>
                            <li>Masking tape</li>
                            <li>Basin and water</li>
                            </ul>
                            <p><strong>&nbsp;</strong></p>
                            <p><strong>Activities</strong></p>
                            <ul>
                            <li>Students count the straws needed to make a raft&nbsp; (Mathematics).</li>
                            <li>Cut a straw to 10 cm length (Mathematics).</li>
                            <li>Take two pairs of straws and stick them with masking tape (Engineering)</li>
                            <li>Insert straws between the straws that have been glued together with masking tape (Engineering).&nbsp;</li>
                            <li>Take an origami paper and cut it into a triangle (Mathematics).</li>
                            <li>To make a flag, take a 10 cm straw and glue it on origami paper that has been cut into triangles (Art).</li>
                            <li>Add certain decorations so that the raft becomes more attractive (Art).</li>
                            </ul>
                            <p>Now, you can run your raft on a basin filled with water (Science).</p>
                        `,
    "TK.articles-three.title": '[TK Mahatma Gandhi Kemayoran] Virtual Field Trip To Ragunan Wildlife Park.',
    "TK.articles-three": `<p>Virtual Field Trip To Ragunan Wildlife Park.</p>
                            <p>&nbsp;</p>
                            <p>Hello Mahatma Gandhi Kemayoran Kindergarten Friends!</p>
                            <p>Warm greetings and longing from us to all of you!</p>
                            <p>Of course, since the beginning of this pandemic we have all been forced to stay at home and recreational areas have been closed. So sad. Friends must have missed traveling with mom, dad and the rest of the family, right? Although some tourist attractions have reopened, they must still adhere to a series of strict health protocols.</p>
                            <p>So, to release their longing and learn about animals, on November 12, 2021, the children of Mahatma Gandhi Kemayoran Kindergarten just went on a trip to the Ragunan Wildlife Park!</p>
                            <p>"Wow, how come there are so many people there? Isn't there still a corona virus?"</p>
                            <p>Don't worry, Mahatma Gandhi Kemayoran Kindergarten children go to Ragunan Wildlife Park for recreation from their respective homes, or commonly known as a Virtual Field Trip. MGS KMY Kindergarten friends must be wondering what a Virtual Field Trip is like? Is it as exciting as walking straight there?</p>
                            <p>Come on, MGS KMY Kindergarten friends, let's take a peek at how exciting a Virtual Field Trip to Ragunan Wildlife Park is</p>
                            <p>Mahatma Gandhi Kemayoran Kindergarten children meet some animals at Ragunan Wildlife Park. First meet ostrich or ostrich, then elephant, giraffe, and finally meet pelican.</p>
                            <p>This activity was guided by all Mahatma Gandhi Kemayoran Kindergarten teachers who had pviously visited and taken live pictures and videos of Ragunan Wildlife Park. During the process of taking pictures or videos, all teachers continue to follow the health protocols that apply at Ragunan Wildlife Park.</p>
                            <p>Ms. Ana greeted the children who were excited to join the Field Trip, sang together and then the activity began with prayer led by K1 children. Do not forget there is also a welcome from Ms. Edith, Principal of Mahatma Gandhi Kemayoran Kindergarten.</p>
                            <p>&nbsp;</p>
                            <p>It turns out that the Ragunan Wildlife Park is very wide and the children will be very happy to be here in person.</p>
                            <p>&nbsp;</p>
                            <p>After seeing the various animals there, the teachers invite us to sing a song called &ldquo;Daddy Taking Us To the Zoo.&rdquo; This recreational activity allows children to have an experience about recreation from home, as well as learn about animals firsthand.</p>
                            <p>if we go for recreation we must keep the environment clean by throwing garbage in the trash so that the environment remains clean and healthy. Especially now that the corona virus is still around us. Children must bring supplies and remain obedient to health protocols, namely washing hands with soap, wearing masks, maintaining distance, staying away from crowds and reducing mobility or leaving the house.</p>
                            <p>&nbsp;</p>
                            <p>The smooth running of this Virtual Field Trip is, of course, a blessing from the Almighty God. Therefore, we do not forget to thank God for this fun day and activities.</p>
                            <p>&nbsp;</p>
                            <p>Well, that's a series of Virtual Field Trip activities for Mahatma Gandhi Kemayoran Kindergarten children. How? Even though it's from home, it's still fun, isn't it?</p>
                            <p>Friends of TK MGS KMY, let's keep praying for this country and the world so that the COVID-19 pandemic can end soon. So that we can get back to our normal activities. You can go back to school, play with friends and teachers, and go for a walk with your family.</p>
                            <p>Keep your health and comply with the health protocol, friends of TK MGS KMY. See you in our other stories!</p>
                            <p>&nbsp;</p>
                        `,
    "TK.articles-four.title": 'Chinese New Year Celebration 2022',
    "TK.articles-four": `<p>Chinese New Year is one of the most important celebrations in Chinese culture.</p>
                            <p>Chinese New Year, also known as Lunar New Year is an extremely valued event within Chinese culture.This Chinese New Year celebration is synonymous with Lion Dance, Angpao, and red decorations.</p>
                            <p>In commemoration of this Lunar New Year anniversary, we are holding a virtual event. Each child makes a video song with a mandarin song, a song that has been determined by the teacher. Videos that have been made are scored and determined who will be the winner. The 2 best people were chosen. Every child gets an angpau as a symbol of this Chinese New Year celebration. We teach you to save when you get angpau. The event went well and was full of excitement.Teachers were ecstatic for the appreciation and love showered upon by their dear students</p>
                        `,
    "TK.articles-five.title": '[TK Mahatma Gandhi Kemayoran] Goes To SKY WORLD',                         
    "TK.articles-five": `<p>TK MGS KMY had a virtual trip to one of the best outer space-themed educational and recreational theme parks in Indonesia. We feed our inner curiosity in an impressive digital planetarium, 5D cinema, and an exhibition room. Mr. Amir as a former form Sky world TMII guides us to see and learn more about outer space, aeronautics, and space technology in a fun and interactive way.</p>
                            <p>Our learning goal for this trip is that children will be able to understand many concepts about space. Sprinkle in vocabulary related to space, and teach those super basic concepts like night and day, the moon, the sun, and the planets.</p>
                            <p>Almost everyone has, at some point, dreamed of exploring outer space. Whether it is just a passing daydream or a fervent ambition, the mysteries of space have always piqued the curiosity in all of us. You might not be an astronaut, but don't worry, Sky world Indonesia is here to fulfill your dream! This theme park aims to educate more Indonesians on topics related to outer space, aeronautics, and space technology.</p>
                            <p>We did an experiment by seeing our sun rotation by using telescope, sunglasses and 3D glasses. Our children so excited when they saw how the way our sun move. We tried 5D cinema also event thought by virtual and all children got a new experience.</p>
                            <p>Hopefully we can visit &ldquo;Sky World TMII&rdquo; during next holiday with our family directly because we can learn and see everything about outer space</p>
                        `,
    "TK.articles-six.title": 'Teachers Day',
    "TK.articles-six": `<p><strong>Happy Teachers Day</strong></p>
                            <p>A good teacher can inspire hope, ignite imagination and instill love for learning&rsquo;<br /> This year on Teachers&rsquo; Day the committee of TK Mahatma Gandhi Kemayoran had a brief but warm celebration for their teachers.<br /> Virtual Teachers&rsquo; Day was filled with exciting and heart-warming presentations from the students and also from the parents. The children showed their affection, regard, and respect for their teachers. They presented speeches, made beautiful cards, dances and songs to express their feelings. <br /> It must be mentioned that everything was organized by the children themselves with parents enthusiastically guiding their children. The day was filled with lots of excitement, joy and happiness.<br /> Teachers were ecstatic for the appreciation and love showered upon by their dear students.</p>
                    `,


    // SD
    "SD.articles-one.title": 'GRADE 6 PRACTICAL EXAMS SDS MAHATMA GANDHI KEMAYORAN',
    "SD.articles-one": `During this year’s practical exams, both in English as well as Bahasa Indonesia, our students showcased their fine use of vocabulary and intonation in the form of speeches and news articles. The topics discussed revolved mainly around education and the importance of gadgets and how they have been a massive help in this day and age where learning has had to go virtual.`,
    
    "SD.articles-two.title": 'KARTINI’S DAY SDS MAHATMA GANDHI KEMAYORAN',
    "SD.articles-two": `
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:36.0pt;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>On April 21 every year at our school there is always a Kartini Day Celebration, R.A Kartini, who we know as a figure of the Emancipation of Indonesian Women, becomes a momentum for women to dare to rise to an equal position with men.</span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:36.0pt;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Not to be missed at our school, both live and virtual, Kartini Day celebrations were held with various activities including competitions that showcased the artistic creativity of students, for example: dancing, singing and reading poetry. The competition category is divided into two groups. The small class group consists of grades 1, 2 and 3. And the large class group consists of grades 4, 5 and 6. The type of competition is determined by the teachers in charge of each competition category.</span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:36.0pt;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>For small class competitions, there are competitions: coloring, singing and fashion shows. Competitions for large classes, for example: Drawing, reading poetry, singing and instruments. The judges selected are teachers who are competent in their respective fields. This Kartini celebration activity aims to foster a sense of appreciation and appreciation of the nation&apos;s arts and culture as well as the activities of Mahatma Gandhi Elementary School students.</span></p>
    `,
    
    "SD.articles-three.title": 'CHINESE NEW YEAR 2022 SDS MAHATMA GANDHI KEMAYORAN',
    "SD.articles-three": `
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:36.0pt;'><span style='font-size:19px;line-height:107%;font-family:"Arial",sans-serif;'>Chinese New Year is one of the major events that is celebrated annually in Mahatma Gandhi School. This year marks the year of the tiger. Due to coronavirus restrictions the event was held virtually with students dressed in red. This is traditionally done as a symbol to ward off evil.</span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:36.0pt;'><span style='font-size:19px;line-height:107%;font-family:"Arial",sans-serif;'>The Lion Dance is commonly performed during Chinese New Year. In normal circumstances, we would have had a Lion Dance happening at school. This really helps to put everyone in the new year spirit.&nbsp;</span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:36.0pt;'><span style='font-size:19px;line-height:107%;font-family:"Arial",sans-serif;'>This year, our students performed the Gong Xi Fat Chai song virtually, followed by a very entertaining tik tok presentation.</span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:36.0pt;'><span style='font-size:19px;line-height:107%;font-family:"Arial",sans-serif;'>Besides songs and dances, Our art teacher showcased a beautiful Chinese New Year themed art and craft project done buy our own little ones from Grades 2 and 3.</span></p>
    `,
    
    "SD.articles-four.title": 'END OF YEAR 2021 PERFOMANCE SDS MAHATMA GANDHI KEMAYORAN',
    "SD.articles-four": `
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:36.0pt;'><span style='font-size:19px;line-height:107%;font-family:"Arial",sans-serif;'>Every year, Mahatma Gandhi School Kemayoran celebrates Christmas as we transit from a year to a new one. We had closed the chapter of 2021 and opened a new page for 2022 by enthusiasm and conducted the event virtually due to the pandemic. However, the heartfelt various activities and showcased festivity did not fail to ignite our Christmas spirit.</span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:36.0pt;'><span style='font-size:19px;line-height:107%;font-family:"Arial",sans-serif;'>It was proven by our students who performed the events such as dancing, singing, storytelling, poetry recitation, and drama. The teachers had also displayed the students Christmas and New Year themed artwork to appreciate and inculcate self-confidence and boost their morale ambience.</span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:36.0pt;'><span style='font-size:19px;line-height:107%;font-family:"Arial",sans-serif;'>Additionally, a dress code was followed to create a livelier. The students and the teachers wore red and white attire, as well as fun Christmas headpieces to top it off.&nbsp;</span></p>
        <p><span style='font-size:19px;line-height:107%;font-family:"Arial",sans-serif;'>The event successfully concluded the year 2021, in a festive way with commendable performances which were recorded to allow us to relive the moments of celebration by visiting our YouTube channel.</span></p>
    `,
    
    // SMP    
    "SMP.articles-one.title": 'Teacher’s Day Celebration 2022',
    'SMP.articles-one': `
        The 'World teachers day' was celebrated at MGS Kemayoran on the 5th of October 2022. It was a heartwarming experience; hosted by the senior students of grade 12 They put up a spectacular show, a mini skit based on a classroom situation, followed by an interesting quiz, and a game of musical chairs. Lovely gift hampers, were gifted to each teacher, and that really made their day. The students next sent in some lovely eats to the teachers staffroom and whilst the staff enjoyed the camaraderie, student teachers took over the managing of classes, and the teachers  spent some leisurely moments together. Post a short break, the staff and all of the students were once again invited to the school auditorium. This time it was for show casing student potential. The little ones of grades 7 and 8 came up with some lovely poetic compositions. This was followed by an amazing display of musical talent, and that was truly a treat to one and all present that day. Much was captured in the minds and much more of it…..in the lens. Now one awaits for yet another time; in times to follow.
    `
    //SMA
}