import React from 'react';
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";
require('dotenv').config();


export default function Footer(props) {  
    return (
      <div>
        <footer id="footer">
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-6 footer-contact">
                  <h4>Address</h4>
                  <p>
                    <strong>MGS Kemayoran</strong> <br />
                    J1. Tabing Blok B16, No.3 Kemayoran, Jakarta Pusat.
                    <br />
                    <strong>Fax :</strong> +6221-6542479
                    <br />
                  </p>
                  <br />
                  <p>
                    <strong>MGS Pasar Baru</strong> <br />
                    JI. Pasar Baru Selatan No.10, Jakarta Pusat.
                    <br />
                    <strong>Fax :</strong> +6221-3508963
                    <br />
                    <strong>Email :</strong> mgsjkt@outlook.com
                    <br />
                  </p>
                </div>
                <div className="col-lg-3 col-md-6 footer-links">
                  <h4>Our Services</h4>
                  <ul>
                    <li>
                      <i className="bx bx-chevron-right" />{" "}
                      <a href="/terms">
                        <FormattedMessage id="menu.terms" />
                      </a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{" "}
                      <a href="/admission">
                        <FormattedMessage id="menu.admission" />
                      </a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{" "}
                      <a href="/important">
                        <FormattedMessage id="menu.important" />
                      </a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{" "}
                      <a href="/child">
                        <FormattedMessage id="menu.child" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-2 col-md-6 footer-links">
                  <ul className="mt-4">
                    <li>
                      <i className="bx bx-chevron-right" />{" "}
                      <a href="/faq">
                        <FormattedMessage id="menu.faq" />
                      </a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{" "}
                      <a href="/inquiry">
                        <FormattedMessage id="menu.inquiry" />
                      </a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{" "}
                      <a href="/fees">
                        <FormattedMessage id="menu.fees" />
                      </a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right" />{" "}
                      <a href="/apply">
                        <FormattedMessage id="menu.apply" />
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-3 col-md-6 footer-links">
                  <h4>
                    <FormattedMessage id="component.text.social-media" />
                  </h4>
                  <div className="social-links mt-3">
                    <a href="/" className="twitter">
                      <i className="bx bxl-twitter" />
                    </a>
                    <a href="/" className="facebook">
                      <i className="bx bxl-facebook" />
                    </a>
                    <a href="/" className="instagram">
                      <i className="bx bxl-instagram" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container py-4">
            <div className="copyright">
              © Copyright{" "}
              <strong>
                <span>BPB</span>
              </strong>
              . All Rights Reserved
            </div>
          </div>
        </footer>
        {/* End Footer */}
        <p />
      </div>
    );
}
