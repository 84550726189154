import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import { Galeries, Achievement } from '../services';
import Parser from 'html-react-parser';
import { Helpers } from '../components';
import { Loading } from '../components';
require('dotenv').config();

const galeries = new Galeries();
const achievement = new Achievement();

export default function Main(props) {

  const helpers = new Helpers();
  const intl = useIntl();
  const locale = helpers.changesLocale(intl.locale)

  const [achievementList, setAchievementList] = useState([]);
  const [achievementLoading, setAchievementLoading] = useState(false);

  const [galleryList, setGalleryList] = useState([]);
  const [gallery, setGallery] = useState({});
  const [path, setPath] = useState('galeries');

  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);


  useEffect(() => {
    (async () => getGalleryList())();
    (async () => getAchievementList())();
    // (async () => getAcademicCalendarList())();
  }, []);


  const getAchievementList = () => {
    setAchievementLoading(true);

    achievement.list({ limit: 100 })
      .then(res => {
        console.log(res);

        setAchievementLoading(false);
        setAchievementList(res.result);
      })
      .catch(err => {
        console.error(err);
        if (err.code === 404) {
          setNotFound(true);
        }

        setAchievementLoading(false);
      })
  }

  const getGalleryList = () => {
    setLoading(true);
    galeries.list({ limit: 100 })
      .then(res => {
        console.log(res);

        setLoading(false);
        setGalleryList(res.result);
      })
      .catch(err => {
        console.error(err);
        if (err.code === 404) {
          setNotFound(true);
        }

        setLoading(false);
      })
  }


  const getImage = (fileName, path) => {
    return `${process.env.REACT_APP_URL_SERVICE}/public/image/${path}/${fileName}`;
  }
  const generateText = (item, key) => {
    return locale === 'id-ID' ? item[key] : item[`en_${key}`];
  }
  return (
    <div>
      <section id="hero" className="d-flex align-items-center">
        <div className="container" data-aos="zoom-out" data-aos-delay={100}>
          <h1>
            <FormattedMessage id="component.text.welcome" /> <br />
            <span>{process.env.REACT_APP_NAME}</span>
          </h1>
        </div>
      </section>
      <main id="main">
        {/* ======= Clients Section ======= */}
        <section id="clients" className="clients section-bg">
          <div className="container" data-aos="zoom-in">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-6 d-flex align-items-center justify-content-center">
                <img
                  src="assets/img/logo/logo board.png"
                  className="img-fluid"
                  style={{ width: "90px", height: "125px" }}
                  alt
                />
              </div>
              <div className="col-lg-3 col-md-3 col-6 d-flex align-items-center justify-content-center">
                <img
                  src="assets/img/logo/logo cambridge.png"
                  className="img-fluid"
                  style={{ width: "90px", height: "125px" }}
                  alt
                />
              </div>
              <div className="col-lg-3 col-md-3 col-6 d-flex align-items-center justify-content-center">
                <img
                  src="assets/img/logo/logo.jpeg"
                  className="img-fluid"
                  style={{ width: "90px", height: "125px" }}
                  alt
                />
              </div>
              <div className="col-lg-3 col-md-3 col-6 d-flex align-items-center justify-content-center">
                <img
                  src="assets/img/logo/logo tutwuri.png"
                  className="img-fluid"
                  style={{ width: "90px", height: "125px" }}
                  alt
                />
              </div>
            </div>
          </div>
        </section>
        {/* End Clients Section */}
        <section id="portfolio" className="portfolio">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>
                <FormattedMessage id="menu.tour" />
              </h2>
              <h3>
                <span>
                  <FormattedMessage id="menu.tour" />
                </span>
              </h3>
            </div>
          </div>
        </section>
        <section id="portfolio" className="portfolio">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>
                <FormattedMessage id="menu.photo" />
              </h2>
              <h3>
                <span>
                  <FormattedMessage id="menu.our-photo" />
                </span>
              </h3>
            </div>
            <div className="row" data-aos="fade-up" data-aos-delay={200}>
              <Loading visible={loading} />
              {!loading && galleryList.length > 0 ? (
                galleryList.map((item) => (
                  <div className="col-lg-4 col-md-6 portfolio-item">
                    {item.type === 1 && (
                      <>
                        <img
                          src={getImage(item.image, "galeries")}
                          className="img-fluid"
                          alt="img"
                          key={item.id}
                        />

                        <div className="portfolio-info">
                          <h4>{generateText(item, 'title')}</h4>
                          <p>
                            <FormattedMessage id="component.text.created-by" />
                            : {item.created_by}
                          </p>
                          <a
                            href="#/"
                            className="venobox preview-link"
                            data-toggle="modal"
                            data-target="#galeryclick"
                            title="App 1"
                            onClick={() => setGallery(item)}
                          >
                            <i className="bx bx-plus" />
                          </a>
                        </div>
                      </>
                    )}
                  </div>
                ))
              ) : !notFound ? null : (
                <FormattedMessage id="component.text.no-records" />
              )}
            </div>
          </div>
        </section>
        {/* Gallery video */}
        <section id="portfolio" className="portfolio">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>
                <FormattedMessage id="menu.video" />
              </h2>
              <h3>
                <span>
                  <FormattedMessage id="menu.our-video" />
                </span>
              </h3>
            </div>
            <div className="row" data-aos="fade-up" data-aos-delay={200}>
              <Loading visible={loading} />
              {!loading && galleryList.length > 0 ? (
                galleryList.map(
                  (item) =>
                    item.type === 2 && (
                      <div className="col-lg-4 col-md-6 portfolio-item">
                        <iframe height="200" src={item.video_url || null} />
                        <div className="portfolio-info">
                          <h4>{generateText(item, 'title')}</h4>
                          <p>
                            <FormattedMessage id="component.text.created-by" />
                            : {item.created_by}
                          </p>
                        </div>
                      </div>
                    )
                )
              ) : !notFound ? null : (
                <FormattedMessage id="component.text.no-records" />
              )}
            </div>
          </div>
        </section>
        <section id="achievment-section" className="achievment-section">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>
                <FormattedMessage id="component.text.achievment" />
              </h2>
              <h3>
                <span>
                  <FormattedMessage id="component.text.our-achievment" />
                </span>
              </h3>
            </div>
            <div
              className="row achievment"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <Loading visible={achievementLoading} />
              {!achievementLoading && achievementList.length > 0 ? (
                achievementList.map((item) => (
                  <div className="col-md-4 col-sm-6">
                    <div className="item">
                      <a
                        href
                        className="venobox preview-link"
                        data-toggle="modal"
                        data-target="#achievmentclick"
                        onClick={() => {
                          setGallery(item);
                          setPath("achievement");
                        }}
                      >
                        {" "}
                        <img
                          src={getImage(item.image, "achievement")}
                          className="img-fluid"
                          alt="img"
                          key={item.id}
                        />{" "}
                        <div className="img-title">{generateText(item, 'title')}</div>
                      </a>
                    </div>
                  </div>
                ))
              ) : !notFound ? null : (
                <FormattedMessage id="component.text.no-records" />
              )}
            </div>
          </div>
        </section>

        <section id="contact" className="contact section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>
                <FormattedMessage id="menu.contact" />
              </h2>
              <h3>
                <span>
                  <FormattedMessage id="menu.contactus" />
                </span>
              </h3>
            </div>
            <div className="row" data-aos="fade-up" data-aos-delay={100}>
              <div className="col-lg-6">
                <div className="info-box mb-4">
                  <i className="bx bx-map" />
                  <h3>
                    <FormattedMessage id="component.text.our-address" />
                  </h3>
                  <p>
                    <strong>MGS Kemayoran</strong> <br />
                    J1. Tabing Blok B16, No.3 Kemayoran, Jakarta Pusat.
                    <br />
                    <strong>Fax :</strong> +6221-6542479
                    <br />
                  </p>
                  <br />
                  <p>
                    <strong>MGS Pasar Baru</strong> <br />
                    JI. Pasar Baru Selatan No.10, Jakarta Pusat.
                    <br />
                    <strong>Fax :</strong> +6221-3508963
                    <br />
                    <strong>Email :</strong> mgsjkt@outlook.com
                    <br />
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="info-box  mb-4">
                  <i className="bx bx-envelope" />
                  <h3>
                    <FormattedMessage id="component.text.email-us" />
                  </h3>
                  <p>mgsjkt@outlook.com</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="info-box  mb-4">
                  <i className="bx bx-phone-call" />
                  <h3>
                    <FormattedMessage id="component.text.call-us" />
                  </h3>
                  <p>
                    <strong>MGS Kemayoran</strong> <br />
                    <strong>Phone :</strong> +6221-6542241, 6542480
                    <br />
                  </p>
                  <p>
                    <strong>MGS Pasar Baru</strong> <br />
                    <strong>Phone :</strong> +6221-3508963
                    <br />
                  </p>
                  <p>
                    <strong>For Admission</strong> <br />
                    <strong>Phone :</strong> +6285283247514
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {Object.keys(gallery).length > 0 && (
          <div
            className="modal fade"
            id="achievmentclick"
            tabIndex={-1}
            aria-labelledby="achievmentclickLabel"
            aria-hidden="true"
            style={{ zIndex: "100000" }}
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div>
                    <img
                      src={getImage(gallery.image, "achievement")}
                      alt="article"
                    />
                    <div className="content">
                      <h1>{generateText(gallery, 'title')}</h1>
                      {Parser(generateText(gallery, 'content'))}

                      <p>{helpers.formatDate(gallery.created_at, 'LLLL', locale)}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {Object.keys(gallery).length > 0 && (
          <div
            className="modal fade"
            id="galeryclick"
            tabIndex={-1}
            aria-labelledby="galeryclickLabel"
            aria-hidden="true"
            style={{ zIndex: "100000" }}
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div>
                    <img
                      src={getImage(gallery.image, "galeries")}
                      alt="article"
                    />
                    <div className="content">
                      <h1>{generateText(gallery, 'title')}</h1>
                      {Parser(generateText(gallery, 'content'))}

                      <p>{helpers.formatDate(gallery.created_at, 'LLLL', locale)}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
}
