export default {
    'menu.about': 'Tentang Kami',
    'menu.event': 'Acara',
    'menu.article': 'Artikel',
    'menu.news': 'Berita Sekolah',
    'menu.gallery': 'Galeri',
    'menu.home': 'Beranda',
    'menu.contact': 'Kontak',
    'menu.contactus': 'Kontak Kami',
    'menu.language': 'Bahasa',
    'menu.testimoni': 'Testimoni',
    'menu.testimonial': 'Testimoni Alumni',
    'menu.tk': 'TK',
    'menu.sd': 'SD',
    'menu.smp': 'SMP',
    'menu.sma': 'SMA',
    'menu.list-alumni': 'Data Alumni',
    'menu.information': 'Informasi',
    'menu.registration': 'Pendaftaran',
    'menu.vacancies': 'Lowongan',
    'menu.photo': 'Foto',
    'menu.our-photo': 'Foto Kami',
    'menu.video': 'Video',
    'menu.our-video': 'Video Kami',
    'menu.portal': 'Portal',
    'menu.cbt': 'CBT',
    'menu.e-raport': 'E-Raport',
    'menu.academic': 'Akademik',
    'menu.non-academic': 'Non Akademik',
    'menu.teacher-profile': 'Profil Guru',
    'menu.tour': 'Tur Sekolah Virtual',
    'menu.vission': 'Visi',
    'menu.mission': 'Misi',
    'menu.principal': 'Kepala Sekolah',
    'menu.chairman': 'Pimpinan',
    'menu.support': 'Support',
    'menu.calendar': 'Kalender',
    'menu.activity-calendar': 'Kalender Kegiatan Sekolah',
    'menu.faq': 'Faq',
    'menu.inquiry': 'Inquiry',
    'menu.fees': 'Fees',
    'menu.apply': 'Apply',
    'menu.terms': 'Terms and Conditional',
    'menu.admission': 'Admission Guidelines',
    'menu.important': 'Important Dates',
    'menu.child': 'Child Safeguarding',
    'menu.policy': 'Kebijakan Sekolah',
    'menu.license': 'Ijin Sekolah',
    'menu.announcement': 'Pengumuman',
    'menu.school-management': 'Management Sekolah',
}