
import React, { useState, useEffect } from 'react';
import { FormattedMessage } from "react-intl";
import { Registration } from '../services';
import { Helpers, Loading } from '../components';
import Parser from 'html-react-parser';

const helpers = new Helpers();
const admission = new Registration();
export default function Admission(props) {  
    const [admissionList, setAdmissionList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [notFound, setNotFound] = useState(false);

    useEffect(() => {
        (async () => getAdmissionList())();
    }, []);

    const getAdmissionList = () => {
        setLoading(true);

        admission.list({ limit: 100 })
        .then(res => {
            console.log(res);            

            setLoading(false);
            setAdmissionList(res.result);
        })
        .catch(err => {
            console.error(err);
            if (err.code === 404) {
                setNotFound(true);
            }
            
            setLoading(false);
        })
    }

    const getImage = (fileName) => {
        return `${process.env.REACT_APP_URL_SERVICE}/public/image/registration/${fileName}`;
    }
    
    return (
        <div>
             <main id="main" data-aos="fade-up">
                <section className="breadcrumbs">
                    <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <h2><FormattedMessage id="menu.admission"/></h2>
                        <ol>
                        <li><a href="/"><FormattedMessage id="menu.home"/></a></li>
                        <li><FormattedMessage id="menu.admission"/></li>
                        </ol>
                    </div>
                    </div>
                </section>
                <section id="list-alumni" className="list-alumni">
                   <div className="container" data-aos="fade-up">
                   <Loading visible={loading}/>
                    {!loading && admissionList.length > 0
                    ? admissionList.map((item, i) => (
                        <div className="card mb-3">
                            <div className="row no-gutters">
                                <div className="col-md-3">
                                    <img 
                                     src={getImage(item.image)} 
                                     alt={item.title}   
                                     style={{width:'250px'}}  />
                                </div>
                                <div className="col-md-9">
                                    <div className="card-body">
                                        <h5 className="card-title">{item.title}</h5>
                                        {/* <p className="card-text">

                                        </p> */}
                                        {Parser(item.content)}
                                        <p className="card-text"><small className="text-muted">Last updated {helpers.formatDate(item.created_at, 'LLL')}</small></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                    : !notFound ? null :  <FormattedMessage id="component.text.no-records"/>
                    }
                   </div>
                </section>
            </main>
        </div>
    )
}
