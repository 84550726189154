import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import { Leadership } from '../services';
import { Helpers, Loading } from '../components';
import Parser from 'html-react-parser';
require('dotenv').config();

const principals = new Leadership();
export default function Principal(props) {
    const helpers = new Helpers();
    const intl = useIntl();
    const locale = helpers.changesLocale(intl.locale)
    
    const [principal, setPrincipal] = useState();
    const [notFound, setNotFound] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => getPrincipalInfo())();
    }, []);

    const getPrincipalInfo = () => {
        setLoading(true);

        principals.getLeadership('PRINCIPAL', locale)
        .then(res => {
            console.log(res);            

            setLoading(false);
            setPrincipal(res.result);
        })
        .catch(err => {
            console.error(err);
            if (err.code === 404) {
                setNotFound(true);
            }
            
            setLoading(false);
        })
    }

    const getImage = (fileName) => {
        return `${process.env.REACT_APP_URL_SERVICE}/public/image/leadership/${fileName}`;
    }
    return (
        <div>
            <main id="main" data-aos="fade-up">
                <section className="breadcrumbs">
                    <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <h2><FormattedMessage id="menu.principal"/></h2>
                        <ol>
                        <li><a href="/"><FormattedMessage id="menu.home"/></a></li>
                        <li><FormattedMessage id="menu.principal"/></li>
                        </ol>
                    </div>
                    </div>
                </section>
                <section id="about" className="inner-page about">
                    <div className="container" data-aos="fade-up">
                        <Loading visible={loading}/>
                        {
                        !loading && principal 
                            ? 
                            (
                                <div className="row">

                                    <div className="col-lg-4" data-aos="zoom-out" data-aos-delay={100}>
                                      <div className="text-center">
                                      <img src={getImage(principal.image)} className="img-fluid" alt="img" />
                                        <h4 className="mt-3 text-center">{principal.name}</h4>
                                      </div>
                                    </div>
                                    <div className="col-lg-8 pt-4 pt-lg-0 content d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay={100}>
                                        <div>
                                            {Parser(principal.content)}
                                        </div>
                                    </div>
                                </div>
                            )
                            : !notFound ? null :  <FormattedMessage id="component.text.no-records"/>
                        }
                    </div>
                </section>
            </main>
        </div>
    )
}
