export default {
  "component.text.no-records": "Data not found",

  "component.text.welcome": "WELCOME TO",
  "component.text.our-address": "Our Address",
  "component.text.email-us": "Email Us",
  "component.text.call-us": "Call Us",

  "component.text.teacher": "Teacher",
  "component.text.our-teacher": "Our Teacher",

  "component.text.achievment": "Achievment",
  "component.text.our-achievment": "Our Achievment",

  "component.text.calendar": "Academic Calendar",
  "component.text.our-calendar": "Our Academic Calendar",

  "component.text.created-by": "Created by",
  "component.text.last-update": "Last Updated",
  "component.text.our-services": "Our Services",
  "component.text.social-media": "Social Media",
  "component.text.detail": "View more",

  "component.text.waiting-data": "Waiting for data...",
  "component.under": "This Sections will be Added and related to Curriculum",
};