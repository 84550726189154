
import React, { useState, useEffect } from 'react';
import { FormattedMessage } from "react-intl";
import { Alumni } from '../services';
import { Helpers, Loading } from '../components';
import Parser from 'html-react-parser';

const helpers = new Helpers();
const alumni = new Alumni();

export default function Alumnies(props) {  
    const [alumniList, setAlumniList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [notFound, setNotFound] = useState(false);

    useEffect(() => {
        (async () => getAlumniList())();
    }, []);

    const getAlumniList = () => {
        setLoading(true);

        alumni.list({ limit: 100 })
        .then(res => {
            console.log(res);            

            setLoading(false);
            setAlumniList(res.result);
        })
        .catch(err => {
            console.error(err);
            if (err.code === 404) {
                setNotFound(true);
            }
            
            setLoading(false);
        })
    }

    const getImage = (fileName) => {
        return `${process.env.REACT_APP_URL_SERVICE}/public/image/vacancies/${fileName}`;
    }

    return (
        <div>
            <main id="main" data-aos="fade-up">
                <section className="breadcrumbs">
                    <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <h2><FormattedMessage id="menu.list-alumni"/></h2>
                        <ol>
                        <li><a href="/"><FormattedMessage id="menu.home"/></a></li>
                        <li><FormattedMessage id="menu.list-alumni"/></li>
                        </ol>
                    </div>
                    </div>
                </section>
                <section id="list-alumni" className="list-alumni">
                    <div className="container" data-aos="fade-up">
                        {/* <div className="row">
                            <div className="col-md-4 mb-5">
                                <select className="form-control">
                                    <option>Tahun 2019</option>
                                    <option>Tahun 2020</option>
                                </select>
                            </div>
                        </div> */}
                        <table className="table">
                            <thead>
                                <tr>
                                <th scope="col">No</th>
                                <th scope="col"><FormattedMessage id="form.name"/></th>
                                <th scope="col"><FormattedMessage id="form.entry"/></th>
                                <th scope="col"><FormattedMessage id="form.grad"/></th>
                                <th scope="col"><FormattedMessage id="form.department"/></th>
                                <th scope="col"><FormattedMessage id="form.profession"/></th>
                                <th scope="col"><FormattedMessage id="form.address"/></th>
                                </tr>
                            </thead>
                            <tbody>
                            {loading && <tr><td colSpan="6"><Loading visible={loading}/></td></tr>}
                            {!loading && alumniList.length > 0
                            ? alumniList.map((item, i) => (
                                <tr>
                                    <td>{i + 1}</td>
                                    <td>{item.name}</td>
                                    <td>{item.year_in}</td>
                                    <td>{item.year_out}</td>
                                    <td>{item.major}</td>
                                    <td>{item.profession}</td>
                                    <td>{item.address}</td>
                                </tr>
                            ))
                            :
                                !notFound ? null :  <tr><td colSpan="6"><FormattedMessage id="component.text.no-records"/></td></tr>                                
                            }
                            </tbody>
                        </table>
                    </div>
                </section>
            </main>
        </div>
    )
}
