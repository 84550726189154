import React, { useState } from 'react';
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";

export default function Topbar(props) { 
        const lsLocale = window.localStorage.getItem('locale');
        const [locale, setLocale] = useState(lsLocale || 'en-us');
        const history = useHistory();
    
        const handleSetLocale = (locale) => {
            window.localStorage.setItem('locale', locale);
            
            history.go(0);
        }
        return (
            <div>
                {/* ======= Top Bar ======= */}
                <div id="topbar" className="d-none d-lg-flex align-items-center fixed-top">
                <div className="container d-flex">
                    <div className="contact-info mr-auto">
                    <i className="icofont-envelope" /> <a href="mgsjkt@outlook.com">mgsjkt@outlook.com</a>
                    <i className="icofont-phone" /> +62-21-6542241
                    </div>
                    <div className="social-links">
                    <a href="/" className="twitter"><i className="icofont-twitter" /></a>
                    <a href="/" className="facebook"><i className="icofont-facebook" /></a>
                    <a href="/" className="instagram"><i className="icofont-instagram" /></a>
                    
                    </div> 
                    </div>
                </div>
             
            </div>
        )
}
