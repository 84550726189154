export default {
  "component.text.no-records": "Data tidak ditemukan",

  "component.text.welcome": "SELAMAT DATANG DI",
  "component.text.our-address": "Alamat Kami",
  "component.text.email-us": "Email Kami",
  "component.text.call-us": "Telepon Kami",

  "component.text.teacher": "Guru",
  "component.text.our-teacher": "Guru Kami",

  "component.text.achievment": "Prestasi",
  "component.text.our-achievment": "Prestasi Kami",

  "component.text.calendar": "Kalender Akademik",
  "component.text.our-calendar": "Kalender Akademik Kami",

  "component.text.created-by": "Dibuat oleh",
  "component.text.last-update": "Terakhir diperbarui",
  "component.text.our-services": "Layanan Kami",
  "component.text.social-media": "Sosial Media",
  "component.text.detail": "Lihat Selengkapnya",

  "component.text.waiting-data": "Sedang memuat data...",
  "component.under": "Bagian ini akan Ditambahkan dan terkait dengan Kurikulum",
};