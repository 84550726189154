require('dotenv').config();
export default class Leadership {
    
    constructor() {}

    getLeadership = async (level, lang = 'id-ID') => {        
        const token = window.localStorage.getItem('token');

        return new Promise((resolve, reject) => {
            console.log(process.env.REACT_APP_URL_SERVICE);
            fetch(`${process.env.REACT_APP_URL_SERVICE}/public/leadership/detail/${level}?lang=${lang}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(res => {
                if (res.status === 401) {
                    window.location.href = '/';
                }
                res.json()
                .then(data => {
                    if(data.status && data.status.code === 200) {
                        return resolve(data);
                    }
                    reject(data);
                });
            })
            .catch(err => {
                reject(err);
            });
        });
    }    
}