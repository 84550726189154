import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";

export default function Navbar(props) {  

    const lsLocale = window.localStorage.getItem('locale');
    const [locale, setLocale] = useState(lsLocale || 'en-us');
    const history = useHistory();

    const handleSetLocale = (locale) => {
        window.localStorage.setItem('locale', locale);
        
        history.go(0);
    }

    return (
      <div>
        <header id="header" className="fixed-top">
          <div className="container-fluid d-flex align-items-center">
            <a href="/" className="logo mr-auto">
              <img src="assets/img/logo.jpeg" className="mr-3" alt="" />
              MAHATMA GANDHI SCHOOL
            </a>
            <nav className="nav-menu d-none d-lg-block">
              <ul>
                <li>
                  <NavLink to="/home" activeClassName="active">
                    <FormattedMessage id="menu.home" />
                  </NavLink>
                </li>
                <li className="drop-down">
                  <NavLink to="/about" activeClassName="active">
                    <FormattedMessage id="menu.about" />
                  </NavLink>

                  <ul>
                    <li>
                      <NavLink to="/principal" activeClassName="active">
                        <FormattedMessage id="menu.principal" />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/school-management" activeClassName="active">
                        <FormattedMessage id="menu.school-management" />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/vission" activeClassName="active">
                        <FormattedMessage id="menu.vission" />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/mission" activeClassName="active">
                        <FormattedMessage id="menu.mission" />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/school-policy" activeClassName="active">
                        <FormattedMessage id="menu.policy" />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/school-license" activeClassName="active">
                        <FormattedMessage id="menu.license" />
                      </NavLink>
                    </li>
                  </ul>
                </li>
                {/* <li className="drop-down"><a href><FormattedMessage id="menu.gallery"/></a>
                                <ul>
                                    <li><NavLink to="/gallery?type=1" from='/'  exact  onClick={() => window.location.href='/gallery?type=1'} activeClassName="active"><FormattedMessage id="menu.photo"/></NavLink></li>
                                    <li><NavLink to="/gallery?type=2" from='/'  exact  onClick={() => window.location.href='/gallery?type=2'} activeClassName="active"><FormattedMessage id="menu.video"/></NavLink></li>
                                </ul>
                            </li>              */}
                <li className="drop-down">
                  <a href>
                    <FormattedMessage id="menu.academic" />
                  </a>
                  <ul>
                    <li>
                      <NavLink
                        to="/academic?level=TK"
                        from="/"
                        exact
                        onClick={() =>
                          (window.location.href = "/academic?level=TK")
                        }
                        activeClassName="active"
                      >
                        <FormattedMessage id="menu.tk" />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/academic?level=SD"
                        from="/"
                        exact
                        onClick={() =>
                          (window.location.href = "/academic?level=SD")
                        }
                        activeClassName="active"
                      >
                        <FormattedMessage id="menu.sd" />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/academic?level=SMP"
                        from="/"
                        exact
                        onClick={() =>
                          (window.location.href = "/academic?level=SMP")
                        }
                        activeClassName="active"
                      >
                        <FormattedMessage id="menu.smp" />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/academic?level=SMA"
                        from="/"
                        exact
                        onClick={() =>
                          (window.location.href = "/academic?level=SMA")
                        }
                        activeClassName="active"
                      >
                        <FormattedMessage id="menu.sma" />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/teacher" activeClassName="active">
                        <FormattedMessage id="menu.teacher-profile" />
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="drop-down">
                  <a href>
                    <FormattedMessage id="menu.news" />
                  </a>
                  <ul>
                    <li>
                      <NavLink
                        to="/article?level=TK"
                        from="/"
                        exact
                        onClick={() =>
                          (window.location.href = "/article?level=TK")
                        }
                        activeClassName="active"
                      >
                        <FormattedMessage id="menu.tk" />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/article?level=SD"
                        from="/"
                        exact
                        onClick={() =>
                          (window.location.href = "/article?level=SD")
                        }
                        activeClassName="active"
                      >
                        <FormattedMessage id="menu.sd" />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/article?level=SMP"
                        from="/"
                        exact
                        onClick={() =>
                          (window.location.href = "/article?level=SMP")
                        }
                        activeClassName="active"
                      >
                        <FormattedMessage id="menu.smp" />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/article?level=SMA"
                        from="/"
                        exact
                        onClick={() =>
                          (window.location.href = "/article?level=SMA")
                        }
                        activeClassName="active"
                      >
                        <FormattedMessage id="menu.sma" />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/announcement" activeClassName="active">
                        <FormattedMessage id="menu.announcement" />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/event" activeClassName="active">
                        <FormattedMessage id="menu.event" />
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="drop-down">
                  <a href>
                    <FormattedMessage id="menu.support" />
                  </a>
                  <ul>
                    <li>
                      <NavLink to="/list-alumni" activeClassName="active">
                        <FormattedMessage id="menu.list-alumni" />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/testimoni" activeClassName="active">
                        <FormattedMessage id="menu.testimonial" />
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="drop-down">
                  <a href>
                    <FormattedMessage id="menu.vacancies" />
                  </a>
                  <ul>
                    <li>
                      <NavLink to="/registration" activeClassName="active">
                        <FormattedMessage id="menu.academic" />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/vacancies" activeClassName="active">
                        <FormattedMessage id="menu.non-academic" />
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="drop-down">
                  <a href>
                    <FormattedMessage id="menu.calendar" />
                  </a>
                  <ul>
                    <li>
                      <NavLink to="/activity-calendar" activeClassName="active">
                        <FormattedMessage id="menu.activity-calendar" />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/calendar" activeClassName="active">
                        <FormattedMessage id="component.text.calendar" />
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="drop-down">
                  <a href>
                    <FormattedMessage id="menu.portal" />
                  </a>
                  <ul>
                    <li>
                      <a href="http://e-raport.mgskmy.sch.id/" target="_blank">
                        <FormattedMessage id="menu.e-raport" />
                      </a>
                    </li>
                  </ul>
                </li>
                {/* <li><NavLink to="/event" activeClassName="active"><FormattedMessage id="menu.event"/></NavLink></li> */}
                <li>
                  <NavLink to="/contact" activeClassName="active">
                    <FormattedMessage id="menu.contact" />
                  </NavLink>
                </li>
                <li className="drop-down">
                  <a href>
                    <FormattedMessage id="menu.language" />
                  </a>
                  <ul>
                    <a
                      className={`pl-3 pr-2 ${
                        locale === "en-us" ? "active" : ""
                      }`}
                      href="#"
                      onClick={() => handleSetLocale("en-us")}
                    >
                      <img
                        src={`${window.location.origin}/english-flag.png`}
                        width="20"
                        height="20"
                        style={{ marginRight: 5 }}
                      />
                      English
                    </a>
                    <a
                      className={`pl-3 pr-5 mt-3 ${
                        locale === "id-id" ? "active" : ""
                      }`}
                      href="#"
                      onClick={() => handleSetLocale("id-id")}
                      // style={{ height: 30 }}
                    >
                      <img
                        src={`${window.location.origin}/indonesian-flag.png`}
                        width="20"
                        height="20"
                        style={{ marginRight: 5 }}
                      />
                      Indonesia
                    </a>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </header>
      </div>
    );
}
