import React, {useState} from 'react';
import {BrowserRouter} from 'react-router-dom';
import { Switch, Route } from "react-router";
import { I18nPropvider, LOCALES } from './i18nProvider';
import Footer from './templates/Footer';
import Home from './content/Home';
import Topbar from './templates/Topbar';
import Navbar from './templates/Navbar';
import About from './pages/About';
import Gallery from './pages/Gallery';
import Event from './pages/Event';
import Article from './pages/Article';
import Contact from './pages/Contact';
import './App.css';
import Testimoni from './pages/Testimoni';
import ListAlumni from './pages/ListAlumni';
import Registration from './pages/Registration';
import Vacancies from './pages/Vacancies';
import Teacher from './pages/Teacher';
import Academic from './pages/Academic';
import Vission from './pages/Vission';
import Mission from './pages/Mission';
import SchoolManagements from './pages/SchoolManagement';
import Principal from './pages/Principal';
import Calendar from './pages/Calendar';
import Activity from './pages/Activity';
import Faq from './pages/Faq';
import Inquiry from './pages/Inquiry';
import Apply from './pages/Apply';
import Terms from './pages/Terms';
import Fees from './pages/Fees';
import Admission from './pages/Admission';
import Important from './pages/Important';
import Child from './pages/Child';
import License from './pages/License';
import Policy from './pages/Policy';
import Announcement from './pages/Announcement';

const App = () => {
  const lsLocale = window.localStorage.getItem('locale');
  const [locale, setLocale] = useState(lsLocale || LOCALES.ENGLISH);

  return(
    <I18nPropvider locale={locale}>
      <div className="App">
        <BrowserRouter>
            <Switch>
                <div>
                  <Topbar />
                  <Navbar/>
                  <Route exact path="/" component={Home} />
                  <Route exact path="/home" component={Home}></Route>
                  <Route exact path="/about" component={About}></Route>
                  <Route exact path="/gallery" component={Gallery}></Route>
                  <Route exact path="/event" component={Event}></Route>
                  <Route exact path="/article" component={Article}></Route>
                  <Route exact path="/contact" component={Contact}></Route>
                  <Route exact path="/testimoni" component={Testimoni}></Route>
                  <Route exact path="/list-alumni" component={ListAlumni}></Route>
                  <Route exact path="/registration" component={Registration}></Route>
                  <Route exact path="/vacancies" component={Vacancies}></Route>
                  <Route exact path="/teacher" component={Teacher}></Route>
                  <Route exact path="/academic" component={Academic}></Route>
                  <Route exact path="/vission" component={Vission}></Route>
                  <Route exact path="/mission" component={Mission}></Route>
                  <Route exact path="/school-management" component={SchoolManagements}></Route>
                  <Route exact path="/principal" component={Principal}></Route>
                  <Route exact path="/calendar" component={Calendar}></Route>
                  <Route exact path="/activity-calendar" component={Activity}></Route>
                  <Route exact path="/faq" component={Faq}></Route>
                  <Route exact path="/inquiry" component={Inquiry}></Route>
                  <Route exact path="/apply" component={Apply}></Route>
                  <Route exact path="/terms" component={Terms}></Route>
                  <Route exact path="/fees" component={Fees}></Route>
                  <Route exact path="/admission" component={Admission}></Route>
                  <Route exact path="/important" component={Important}></Route>
                  <Route exact path="/child" component={Child}></Route>
                  <Route exact path="/school-policy" component={Policy}></Route>
                  <Route exact path="/school-license" component={License}></Route>
                  <Route exact path="/announcement" component={Announcement}></Route>
                  <Footer />
                </div>
            </Switch>
        </BrowserRouter>
      </div>
    </I18nPropvider>
  );
}

export default App;
