export default {
    "TK.articles-one.title": 'Membuat Telepon Sederhana',
    "TK.articles-one":  `<p><span style="font-size: 12pt;">Sahabat PAUD, saat ini kita sudah sampai pada tema komunikasi. </span>&nbsp;</p>
                                    <p><span style="font-size: 12pt;">Ada beberapa jenis sistem komunikasi yang kita kenal, yaitu berkomunikasi secara lisan, tertulis dan visual. </span>&nbsp;</p>
                                    <p><span style="font-size: 12pt;">Komunikasi lisan pun ada dua jenis yaitu langsung dan tidak langsung. Kali ini, anak-anak TK Mahatma Gandhi Pasar Baru mempelajari jenis komunikasi secara tidak langsung dengan menggunakan telepon.</span>&nbsp;</p>
                                    <p><span style="font-size: 12pt;">Siswa belajar tentang telepon melalui kegiatan membuat telepon<br>dari bahan sederhana yang ada di kelas.</span>&nbsp;</p>
                                    <p>&nbsp;&nbsp;</p>
                                    <p><span style="font-size: 12pt;"><strong>Bahan yang diperlukan antara lain :</strong></span><strong> </strong></p>
                                    <p style="margin-left:36pt;"><span style="color: black;font-size: 12pt;font-family: Noto Sans Symbols;">●</span> <span style="font-size: 12pt;">Buku gambar</span>&nbsp;</p>
                                    <p style="margin-left:36pt;"><span style="font-size: 12pt;font-family: Noto Sans Symbols;">●</span> <span style="font-size: 12pt;">Sedotan</span>&nbsp;</p>
                                    <p style="margin-left:36pt;"><span style="font-size: 12pt;font-family: Noto Sans Symbols;">●</span> <span style="font-size: 12pt;">Kertas asturo</span>&nbsp;</p>
                                    <p style="margin-left:36pt;"><span style="font-size: 12pt;font-family: Noto Sans Symbols;">●</span> <span style="font-size: 12pt;">Lem</span>&nbsp;</p>
                                    <p style="margin-left:36pt;"><span style="font-size: 12pt;font-family: Noto Sans Symbols;">●</span> <span style="font-size: 12pt;">Gunting</span>&nbsp;&nbsp;</p>
                                `,
    "TK.articles-two.title": 'Membuat Rakit',                            
    "TK.articles-two":  `<p>&nbsp;<span style="background-color: white;font-size: 12pt;font-family: Quattrocento Sans;">Halo Ayah Bunda<br>dan sahabat PAUD! Kita pasti sudah terbiasa dengan istilah STEAM, yaitu pendekatan pembelajaran inovatif yang sangat populer<br>di abad ke-21 ini.</span>&nbsp;</p>
                            <p><span style="background-color: white;font-size: 12pt;font-family: Quattrocento Sans;">STEAM singkatan dari<br>Science, Technology, Engineering, Art dan Mathematics. </span>&nbsp;</p>
                            <p><span style="background-color: white;font-size: 12pt;font-family: Quattrocento Sans;">Jadi pendekatan STEAM adalah proses pembelajaran yang<br>memasukkan unsur Science, Technology, Engineering, Art dan Mathematics dalam<br>aktivitas pembelajaran.</span>&nbsp;</p>
                            <p><span style="background-color: white;font-size: 12pt;font-family: Quattrocento Sans;">Nah, kali ini<br>anak-anak TK.B Mahatma Gandhi Pasar Baru coba menerapkan konsep STEAM ini dalam pembelajaran bertema<br>pekerjaan. Salah satu pekerjaan yang dipilih adalah Nelayan.</span>&nbsp;</p>
                            <p><span style="background-color: white;font-size: 12pt;font-family: Quattrocento Sans;">Selain alat<br>memancing dan jaring penangkap ikan, seorang nelayan harus memiliki sebuah<br>perahu untuk bisa berlayar mengarungi lautan.</span>&nbsp;</p>
                            <p><span style="background-color: white;font-size: 12pt;font-family: Quattrocento Sans;">Pada zaman<br>dahulu, nelayan tradisional kita biasanya menggunakan rakit untuk menangkap<br>ikan di sungai atau di danau.</span>&nbsp;</p>
                            <p><span style="background-color: white;font-size: 12pt;font-family: Quattrocento Sans;">Sahabat PAUD, ayo<br>mari kita belajar tentang rakit yang digunakan oleh para nelayan dengan membuat<br>rakit sederhana dari sedotan plastik.</span>&nbsp;</p>
                            <p><span style="background-color: white;font-size: 12pt;font-family: Quattrocento Sans;">Adapun tujuan<br>pembelajaran ini adalah agar siswa mengenal kendaraan tradisional yang<br>digunakan oleh nelayan untuk menangkap ikan di laut, sungai atau danau.</span>&nbsp;&nbsp;</p>
                            <p></p>
                            <p>&nbsp;<span style="background-color: white;font-size: 14pt;font-family: Quattrocento Sans;">Alat dan Bahan</span>&nbsp;</p>
                            <p style="margin-left:36pt;"><span style="background-color: white;font-size: 12pt;font-family: Quattrocento Sans;">●</span> <span style="background-color: white;font-size: 12pt;font-family: Quattrocento Sans;">Sedotan</span>&nbsp;</p>
                            <p style="margin-left:36pt;"><span style="background-color: white;font-size: 12pt;font-family: Quattrocento Sans;">●</span> <span style="background-color: white;font-size: 12pt;font-family: Quattrocento Sans;">Kertas origami</span>&nbsp;</p>
                            <p style="margin-left:36pt;"><span style="background-color: white;font-size: 12pt;font-family: Quattrocento Sans;">●</span> <span style="background-color: white;font-size: 12pt;font-family: Quattrocento Sans;">Lem</span>&nbsp;</p>
                            <p style="margin-left:36pt;"><span style="background-color: white;font-size: 12pt;font-family: Quattrocento Sans;">●</span> <span style="background-color: white;font-size: 12pt;font-family: Quattrocento Sans;">Gunting</span>&nbsp;</p>
                            <p style="margin-left:36pt;"><span style="background-color: white;font-size: 12pt;font-family: Quattrocento Sans;">●</span> <span style="background-color: white;font-size: 12pt;font-family: Quattrocento Sans;">Selotip</span>&nbsp;</p>
                            <p style="margin-left:36pt;"><span style="background-color: white;font-size: 12pt;font-family: Quattrocento Sans;">●</span> <span style="background-color: white;font-size: 12pt;font-family: Quattrocento Sans;">Baskom dan air</span>&nbsp;</p>
                            <p>&nbsp;&nbsp;</p>
                            <p><span style="background-color: white;font-size: 14pt;font-family: Quattrocento Sans;">Langkah-langkah Membuat Rakit</span>&nbsp;</p>
                            <p style="margin-left:18pt;"><span style="background-color: white;font-size: 10pt;font-family: Noto Sans Symbols;">●</span> <span style="background-color: white;font-size: 12pt;font-family: Quattrocento Sans;">Hitung jumlah sedotan yang dibutuhkan untuk membuat sebuah rakit (Unsur Mathematics).</span>&nbsp;</p>
                            <p style="margin-left:18pt;"><span style="background-color: white;font-size: 10pt;font-family: Noto Sans Symbols;">●</span> <span style="background-color: white;font-size: 12pt;font-family: Quattrocento Sans;">Potong sebuah sedotan dengan ukuran 10 cm atau sesuai arahan guru (Unsur Mathematics).</span>&nbsp;</p>
                            <p style="margin-left:18pt;"><span style="background-color: white;font-size: 10pt;font-family: Noto Sans Symbols;">●</span> <span style="background-color: white;font-size: 12pt;font-family: Quattrocento Sans;">Ambil dua pasang sedotan lalu beri selotip pada ujung-ujungnya (Unsur Engineering).</span>&nbsp;</p>
                            <p style="margin-left:18pt;"><span style="background-color: white;font-size: 10pt;font-family: Noto Sans Symbols;">●</span> <span style="background-color: white;font-size: 12pt;font-family: Quattrocento Sans;">Masukkan sedotan ke sela-sela sedotan yang sudah direkatkan dengan selotip (Unsur Engineering).</span>&nbsp;</p>
                            <p style="margin-left:18pt;"><span style="background-color: white;font-size: 10pt;font-family: Noto Sans Symbols;">●</span> <span style="background-color: white;font-size: 12pt;font-family: Quattrocento Sans;">Ambil kertas origami yang sudah disiapkan lalu digunting membentuk segitiga (Unsur Mathematics).</span>&nbsp;</p>
                            <p style="margin-left:18pt;"><span style="background-color: white;font-size: 10pt;font-family: Noto Sans Symbols;">●</span> <span style="background-color: white;font-size: 12pt;font-family: Quattrocento Sans;">Untuk membuat bendera, ambil sedotan berukuran 10 cm lalu dilem pada kertas origami yang sudah digunting berbentuk segitiga (Unsur Art).</span>&nbsp;</p>
                            <p style="margin-left:18pt;"><span style="background-color: white;font-size: 10pt;font-family: Noto Sans Symbols;">●</span> <span style="background-color: white;font-size: 12pt;font-family: Quattrocento Sans;">Tambahkan hiasan tertentu sehingga rakit menjadi lebih indah (Unsur Art).</span>&nbsp;&nbsp;</p>
                        `,
    "TK.articles-three.title": '[TK Mahatma Gandhi Kemayoran] Virtual Field Trip Ke Taman Margasatwa Ragunan',  
    "TK.articles-three": `<p>Halo Sahabat TK Mahatma Gandhi Kemayoran!</p>
                            <p>Salam hangat dan &nbsp;rindu dari kami untuk kalian semua!</p>
                            <p>Tentu saja sejak awal pandemi ini kita semua terpaksa harus diam di rumah dan tempat rekreasi pun ditutup. Sedih sekali ya. Sahabat pasti juga sudah rindu jalan-jalan bersama mama, papa dan keluarga yang lain kan? Walau sudah ada beberapa tempat wisata kembali dibuka, tetapi harus tetap mentaati serangkaian protokol kesehatan yang ketat.</p>
                            <p>Nah, untuk melepas kerinduan sekaligus belajar tentang binatang, pada tanggal 12 November 2021, anak-anak TK Mahatma Gandhi Kemayoran baru saja jalan-jalan ke Taman Margasatwa Ragunan loh!<br /> <em>&ldquo;Wah kok bisa ramai-ramai ke sana? Kan masih ada virus corona?&rdquo;</em><br /> Tenang saja, anak-anak TK Mahatma Gandhi Kemayoran berekreasi ke Taman Margasatwa Ragunan tetap dari rumah masing-masing, atau biasa disebut&nbsp;<em>Virtual Field Trip</em>. Pasti Sahabat TK MGS KMY bertanya-tanya seperti apa ya&nbsp;<em>Virtual Field Trip&nbsp;</em>itu? Apakah sama serunya dengan jalan-jalan langsung ke sana?</p>
                            <p>Yuk, Sahabat TK MGS KMY, kita intip betapa serunya&nbsp;<em>Virtual Field Trip</em>&nbsp;ke Taman Margasatwa Ragunan!</p>
                            <p>Anak-anak TK Mahatma Gandhi Kemayoran bertemu beberapa binatang di Taman Margasatwa Ragunan. Pertama bertemu burung unta atau ostrich, lalu gajah, jerapah, dan terakhir bertemu dengan burung pelikan.</p>
                            <p>Kegiatan ini dipandu oleh seluruh guru TK Mahatma Gandhi Kemayoran&nbsp; yang sebelumnya sudah melakukan kunjungan serta melakukan pengambilan gambar dan video Taman Margasatwa Ragunan secara langsung. Selama proses pengambilan gambar atau video semua guru tetap mengikuti protocol kesehatan yang berlaku di Taman Margasatwa Ragunan.</p>
                            <p>Ms. Ana menyapa anak-anak yang semangat untuk mengikuti&nbsp;<em>Field Trip</em>, bernyanyi bersama dan kemudian kegiatan dimulai dengan berdoa yang dipimpin oleh anak &ndash; anak K1. Tidak lupa juga ada sambutan dari Ms. Edith, Kepala TK Mahatma Gandhi Kemayoran.</p>
                            <p>&nbsp;</p>
                            <p>Ternyata Taman Margasatwa Ragunan sangat luas sekali dan pasti anak anak senang sekali apabila berada di sini secara langsung.</p>
                            <p>&nbsp;</p>
                            <p>Setelah melihat berbagai hewan disana, para guru mengajak kita untuk menyanyikan lagu berjudul &ldquo;Daddy Taking Us To the Zoo.&rdquo; Kegiatan rekreasi ini membuat anak-anak mempunyai pengalaman tentang berekreasi dari rumah, sekaligus belajar tentang binatang secara langsung.</p>
                            <p>jika kita pergi rekreasi kita harus menjaga kebersihan lingkungan dengan membuang sampah di tempat sampah agar lingkungannya tetap bersih dan sehat. Apalagi sekarang virus corona masih ada di sekitar kita. Anak-anak harus membawa bekal dan tetap patuh terhadap protokol kesehatan yaitu mencuci tangan dengan sabun, memakai masker, menjaga jarak, menjauhi kerumunan dan mengurangi mobilitas atau keluar rumah.</p>
                            <p>&nbsp;</p>
                            <p>Kelancaran kegiatan&nbsp;<em>Virtual Field Trip</em>&nbsp;ini tentu saja merupakan berkat dari Tuhan YME yang Maha Baik. Oleh karena itu, tidak lupa kami berterima kasih kepada Tuhan atas hari dan kegiatan yang menyenangkan ini.</p>
                            <p>&nbsp;</p>
                            <p>Nah, itulah serangkaian kegiatan&nbsp;<em>Virtual Field Trip</em>&nbsp;anak-anak TK Mahatma Gandhi Kemayoran. Bagaimana? Walau dari rumah tapi tetap seru bukan?</p>
                            <p>Sahabat TK MGS KMY, mari kita tetap berdoa bagi negara dan dunia ini agar pandemi COVID-19 bisa segera berakhir ya. Supaya kita bisa kembali beraktivitas seperti biasa kembali. Bisa kembali belajar di sekolah, bermain bersama teman-teman dan ibu guru, juga jalan-jalan bersama keluarga.</p>
                            <p>Tetap jaga kesehatan dan mematuhi protokol kesehatan ya, Sahabat TK MGS KMY. Sampai jumpa lagi di cerita kami yang lainnya!</p>
                        `,
    "TK.articles-four.title": 'Perayaan Tahun Baru Imlek 2022',
    "TK.articles-four": `<p style="text-align:justify;"><span style="color: rgb(32,33,36);background-color: rgb(248,249,250);font-size: 12pt;font-family: Century Gothic", "sans-serif;">Perayaan Tahun Baru Imlek<br>2022. </span>&nbsp;</p>
                            <p style="text-align:justify;"><span style="color: black;background-color: white;font-size: 12pt;font-family: Century Gothic", "sans-serif;">Imlek merupakan salah<br>satu perayaan paling penting dalam budaya Tiongkok.</span><br><span style="color: black;background-color: white;font-size: 12pt;font-family: Century Gothic", "sans-serif;">Tahun ini, Imlek diperingati pada 1 Februari<br>2022. Perayaan Imlek ini identik dengan Barongsai, Angpao, hingga dekorasi<br>berwarna merah.</span>&nbsp;</p>
                            <p style="text-align:justify;"><span style="color: rgb(32,33,36);background-color: rgb(248,249,250);font-size: 12pt;font-family: Century Gothic", "sans-serif;">Dalam rangka memperingati<br>peringatan Tahun Baru Imlek kali ini, kami membuat acara secara virtual. Setiap<br>anak membuat sebuah video lagu dengan lagu mandarin, lagu yang sudah di<br>tentukan oleh guru. Video yang sudah di buat di nilai dan di tentukan siapa<br>yang menjadi pemenangnya. Terpilih 2 orang yang terbaik. Setiap anak mendapat<br>angpau sebagai simbol perayaan imlek kali ini. Kami mengajarkan untuk menabung<br>apabila mendapatkan angpau. Acara berlangsung dengan baik dan penuh dengan<br>kegembiraan. </span>&nbsp;&nbsp;</p>
                        `,
    "TK.articles-five.title": '[TK Mahatma Gandhi Kemayoran] Pergi ke SKY WORLD',                        
    "TK.articles-five": `<p>TK MGS KMY melakukan Virtual Trip ke salah satu theme park edukatif dan rekreasi bertema luar angkasa terbaik di Indonesia. Kami mengedukasi rasa &nbsp;keingintahuan anak di planetarium digital yang mengesankan, bioskop 5D, dan ruang pameran. Pak Amir sebagai perwakilan dari Sky world TMII membimbing kami untuk melihat dan mempelajari lebih jauh tentang luar angkasa, aeronautika, dan teknologi luar angkasa dengan cara yang menyenangkan dan interaktif.</p>
                            <p>&nbsp;</p>
                            <p>Tujuan pembelajaran kami dalam perjalanan ini agar anak-anak dapat memahami banyak konsep tentang ruang. Taburkan kosakata yang berkaitan dengan luar angkasa, dan ajarkan konsep-konsep super dasar seperti malam dan siang, bulan, matahari, dan planet-planet.</p>
                            <p>Hampir setiap orang, pada titik tertentu, bermimpi menjelajahi luar angkasa. Entah itu hanya lamunan yang lewat atau ambisi yang membara, misteri ruang angkasa selalu menggelitik rasa ingin tahu kita semua. Anda mungkin bukan astronot, tapi jangan khawatir, Sky world Indonesia hadir untuk mewujudkan impian Anda! Di taman hiburan ini bertujuan untuk mengedukasi lebih banyak orang Indonesia tentang topik-topik yang berkaitan dengan luar angkasa, aeronautika, dan teknologi luar angkasa.</p>
                            <p>&nbsp;</p>
                            <p>Kami melakukan percobaan dengan melihat rotasi matahari kami dengan menggunakan teleskop, kacamata hitam dan kacamata 3D. Anak-anak kami sangat senang ketika mereka melihat bagaimana cara matahari kami bergerak. Kami mencoba bioskop 5D juga acara yang dipikirkan secara virtual dan semua anak mendapat pengalaman baru.</p>
                            <p>&nbsp;</p>
                            <p>Semoga kita bisa mengunjungi &ldquo;Sky World TMII&rdquo; pada liburan berikutnya bersama keluarga secara langsung karena kita bisa belajar dan melihat segala sesuatu tentang luar angkasa.</p>
                        `,
    "TK.articles-six.title": 'Hari Guru',      
    "TK.articles-six": `<p>Hari Guru.</p>
                            <p>Seorang guru yang baik dapat menginspirasi harapan, menyalakan imajinasi dan menanamkan cinta untuk belajar.</p>
                            <p>Tahun ini &ldquo;Komite TK Mahatma Gandhi Kemayoran mengadakan perayaan &ldquo;Hari Guru&rdquo; yang singkat namun hangat untuk para guru mereka.</p>
                            <p>Hari Guru Virtual diisi dengan psentasi yang menarik dan menghangatkan hati dari para siswa dan juga dari orang tua. Anak-anak menunjukkan kasih sayang, rasa hormat, dan rasa hormat mereka kepada guru mereka. Mereka mempsentasikan pidato, membuat kartu indah, tarian dan lagu untuk mengekspsikan perasaan mereka.</p>
                            <p>Harus disebutkan bahwa semuanya diatur oleh anak-anak sendiri dengan orang tua yang antusias membimbing anak-anak mereka. Hari itu dipenuhi dengan banyak kegembiraan, kegembiraan dan kebahagiaan.</p>
                            <p>Guru sangat gembira atas apsiasi dan cinta yang dicurahkan oleh siswa tersayang mereka.</p>
                        `,
    
    
    // SD
    "SD.articles-one.title": 'UJIAN PRAKTEK KELAS 6 SDS MAHATMA GANDHI KEMAYORAN 14/3/2022 – 18/3/2022',
    "SD.articles-one": `Selama ujian praktek tahun ini, baik dalam bahasa Inggris maupun Bahasa Indonesia, siswa kami menunjukkan penggunaan kosakata dan intonasi yang baik dalam bentuk pidato dan artikel berita. Topik yang dibahas berkisar seputar pendidikan dan pentingnya gadget dan bagaimana gadget telah menjadi bantuan besar di zaman sekarang ini di mana pembelajaran harus dilakukan secara virtual.`,
    
    "SD.articles-two.title": 'HARI KARTINI SDS MAHATMA GANDHI KEMAYORAN',
    "SD.articles-two": `
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:36.0pt;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Tanggal 21 April &nbsp; tiap tahunnya di sekolah kita selalu diadakan Perayaan Hari Kartini, R.A Kartini yang kita kenal sebagai tokoh Emansipasi Wanita &nbsp;Indonesia menjadi momentum bagi kaum perempuan untuk berani bangkit mencapai kedudukan yang setara dengan kaum laki-laki.&nbsp;</span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Tidak ketinggalan di sekolah kita baik secara langsung maupun virtual, perayaan Hari kartini diadakan dengan berbagai kegiatan yang meliputi perlombaan yang menampilkan kreatifitas seni siswa, misalnya : menari, menyanyi serta membaca puisi. &nbsp;Kategori lomba dibedakan menjadi dua kelompok. Kelompok kelas kecil terdiri dari kelas 1, 2 dan 3. Dan kelompok kelas besar yang terdiri dari kelas 4, 5 dan 6. Adapun jenis lomba ditentukan oleh para guru yang bertugas di masing-masing kategori lomba.</span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Untuk Lomba kelas kecil ada lomba : Mewarnai, menyanyi dan fasion show. Lomba untuk kelas besar misalnya : Menggambar, baca puisi, menyanyi dan instrument. Juri yang dipilih adalah guru-guru yang berkompetensi di bidangnya masing-masing. Kegiatan perayaan Kartini ini bertujuan untuk menumbuhkan rasa menghargai dan mengaprasiasi seni dan budaya bangsa serta keatifitas siswa Sekolah Dasar Mahatma Gandhi.</span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
    `,

    "SD.articles-three.title": 'HARI RAYA IMLEK 2022 SDS MAHATMA GANDHI KEMAYORAN',
    "SD.articles-three": `
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:36.0pt;'><span style='font-size:19px;line-height:107%;font-family:"Arial",sans-serif;'>Tahun Baru Imlek adalah salah satu acara besar yang dirayakan setiap tahun di Sekolah Mahatma Gandhi. Tahun ini menandai tahun harimau. Karena pembatasan coronavirus acara diadakan secara virtual dengan siswa berpakaian merah. Ini secara tradisional dilakukan sebagai simbol untuk menangkal kejahatan.</span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:36.0pt;'><span style='font-size:19px;line-height:107%;font-family:"Arial",sans-serif;'>Tarian Singa biasanya dilakukan selama Tahun Baru Cina. Dalam keadaan normal, kami akan mengadakan Tarian Singa di sekolah. Ini sangat membantu untuk menempatkan semua orang dalam semangat tahun baru.</span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:36.0pt;'><span style='font-size:19px;line-height:107%;font-family:"Arial",sans-serif;'>Tahun ini, siswa kami menyanyikan lagu Gong Xi Fat Chai secara virtual, dilanjutkan dengan presentasi tiktok yang sangat menghibur. Selain lagu dan tarian, guru seni kami memamerkan proyek seni dan kerajinan bertema Tahun Baru Imlek yang indah yang dilakukan dengan membeli anak-anak kecil kami sendiri dari Kelas 2 dan 3.</span></p>
    `,
    
    "SD.articles-four.title": 'PENAMPILAN ACARA AKHIR TAHUN 2021 SDS MAHATMA GANDHI KEMAYORAN',
    "SD.articles-four": `
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:36.0pt;'><span style='font-size:19px;line-height:107%;font-family:"Arial",sans-serif;'>Setiap tahun, Sekolah Mahatma Gandhi Kemayoran merayakan Natal sebagai transisi dari tahun ke tahun baru. Kami telah menutup bab tahun 2021 dan membuka halaman baru untuk tahun 2022 dengan mengadakan acara secara virtual karena pandemi. Namun, perayaan yang sepenuh hati itu tidak gagal untuk menyalakan semangat Natal kami.</span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:36.0pt;'><span style='font-size:19px;line-height:107%;font-family:"Arial",sans-serif;'>Terbukti dengan para siswa kami yang menampilkan berbagai bakat mereka di acara tersebut seperti menari, menyanyi, mendongeng, membaca puisi, dan drama. Para guru juga telah menampilkan karya seni bertema Natal dan Tahun Baru siswa untuk menghargai peningkatan mereka.</span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:36.0pt;'><span style='font-size:19px;line-height:107%;font-family:"Arial",sans-serif;'>Selain itu, aturan berpakaian ditetapkan untuk menciptakan perayaan yang lebih hidup di mana para siswa dan guru mengenakan pakaian merah dan putih, serta hiasan kepala Natal yang menyenangkan untuk melengkapinya.</span></p>
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:36.0pt;'><span style='font-size:19px;line-height:107%;font-family:"Arial",sans-serif;'>Acara tersebut telah berhasil ditutup tahun 2021 dengan meriah dengan penampilan terpuji yang direkam untuk memungkinkan kami menghidupkan kembali perayaan dengan mengunjungi saluran YouTube kami.</span></p>
    `,
    
    // SMP    
    "SMP.articles-one.title": 'Teacher’s Day Celebration 2022',
    'SMP.articles-one': `
        <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:36.0pt;'><span style='font-size:19px;line-height:107%;font-family:"Arial",sans-serif;'>&apos;Hari Guru Sedunia&apos; diperingati di MGS Kemayoran pada tanggal 5 Oktober 2022. Sungguh pengalaman yang mengharukan; dipandu oleh siswa senior kelas 12 Mereka melakukan pertunjukan spektakuler, drama komedi mini berdasarkan situasi kelas, diikuti dengan kuis yang menarik, dan permainan kursi musik. Keranjang hadiah yang indah, diberikan kepada setiap guru, dan itu benar-benar membuat hari mereka menyenangkan. Para siswa selanjutnya mengirimkan beberapa makanan enak ke ruang staf guru dan sementara staf menikmati persahabatan, guru siswa mengambil alih pengelolaan kelas, dan para guru menghabiskan waktu santai bersama. Setelah istirahat sejenak, staf dan semua siswa sekali lagi diundang ke auditorium sekolah. Kali ini untuk menunjukkan casing potensi siswa. Anak-anak kecil dari kelas 7 dan 8 datang dengan beberapa komposisi puitis yang indah. Ini diikuti oleh pertunjukan bakat musik yang luar biasa, dan itu benar-benar merupakan suguhan bagi semua yang hadir hari itu. Banyak yang terekam dalam pikiran dan lebih banyak lagi&hellip;..di dalam lensa. Sekarang seseorang menunggu untuk waktu yang lain; dalam waktu untuk mengikuti.</span></p>
    `
    //SMA
}