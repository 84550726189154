import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import { Events } from '../services';
import Parser from 'html-react-parser';
import { Helpers } from '../components';
import { Loading } from '../components';
require('dotenv').config();

const events = new Events();

export default function Event(props) {  
    
    
    const helpers = new Helpers();
    const intl = useIntl();
    const locale = helpers.changesLocale(intl.locale)

    const [eventList, setEventList] = useState([]);
    const [event, setEvent] = useState({});
    const [loading, setLoading] = useState(false);
    const [notFound, setNotFound] = useState(false);

    useEffect(() => {
        (async () => getEventList())();
    }, []);

    const getEventList = () => {
        setLoading(true);

        events.list({ limit: 100 })
        .then(res => {
            console.log(res);            

            setLoading(false);
            setEventList(res.result);
        })
        .catch(err => {
            console.error(err);
            if (err.code === 404) {
                setNotFound(true);
            }
            
            setLoading(false);
        })
    }

    const getImage = (fileName) => {
        return `${process.env.REACT_APP_URL_SERVICE}/public/image/events/${fileName}`;
    }
    
    const generateText = (item, key) => {
        return locale === 'id-ID' ? item[key] : item[`en_${key}`];
    }

    return (
        <div>
            <main id="main" data-aos="fade-up">
                <section className="breadcrumbs">
                    <div className="container">
                        <div className="d-flex justify-content-between align-items-center">
                            <h2><FormattedMessage id="menu.event"/></h2>
                            <ol>
                            <li><a href="/"><FormattedMessage id="menu.home"/></a></li>
                            <li><FormattedMessage id="menu.event"/></li>
                            </ol>
                        </div>
                    </div>
                </section>
                <div className="container">
                    <div><a href="#top-toc"><i className="icofon-curved-up" /></a></div>
                    <div className="white-spacer" style={{height: '4rem'}} />
                    <div className="eventWrapper">
                        <Loading visible={loading}/>
                        {!loading && eventList.length > 0
                        ? eventList.map((item, i) => (
                            <div className="event" key={i} onClick={() => setEvent(item)}>
                                <div className="event--img">
                                    <a href="#/" data-toggle="modal" data-target="#photoclick" className="w-fancybox">
                                        <img 
                                            src={getImage(item.image)} 
                                            title={item.title} 
                                            alt={item.title} />
                                    </a>
                                </div>
                                <div className="event--date">
                                    <span>{helpers.formatDate(item.start_at, 'MMM', locale)}</span>
                                    <span>{helpers.formatDate(item.start_at, 'DD', locale)}</span>
                                    <span>{helpers.formatDate(item.start_at, 'YYYY', locale)}</span>
                                </div>
                                <div className="event--content">
                                    <h2>
                                        <a 
                                            href="#" 
                                            data-toggle="modal" 
                                            data-target="#photoclick"
                                        >
                                            {generateText(item, 'title')}
                                        </a>
                                    </h2>
                                    <div className="event--content-info">
                                        <div>
                                            <i className="icofont-clock-time"></i> 
                                            {helpers.formatDate(item.start_at, 'LLLL', locale)} - {helpers.formatDate(item.end_at, 'LLLL', locale)}
                                        </div>
                                        <div className="event--content-price">
                                            <i className="icofont-book-mark"></i> 
                                            {item.category.name}
                                        </div>
                                        <div className="event--content-tickets">
                                            <a href="#" title="Tickets"> 
                                                <i className="icofont-ticket"></i> 
                                                {helpers.formatNumber(item.fee)}
                                            </a>
                                        </div>
                                    </div>
                                </div>	
                            </div>
                        )) 
                        : !notFound ? null :  <FormattedMessage id="component.text.no-records"/>
                        }
                        
                    </div>                
                    <div className="wsite-spacer" style={{height: '4rem'}} />
                </div>

            </main>
            {Object.keys(event).length > 0 && (
                <div className="modal fade" id="photoclick" tabIndex={-1} aria-labelledby="photoclickLabel" aria-hidden="true" style={{zIndex: '100000'}}>               
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div>
                                    <img 
                                        src={getImage(event.image)} 
                                        alt={event.title} />
                                    <div className="event--content">
                                        <h1>{event.title}</h1>
                                        {Parser(generateText(event, 'content'))}
                                        <div className="event--content-info">
                                            <div>
                                                <i className="icofont-clock-time"></i> 
                                                {helpers.formatDate(event.start_at, 'LLLL', locale)} - {helpers.formatDate(event.end_at, 'LLLL', locale)}
                                            </div>
                                            <div className="event--content-price"><i className="icofont-book-mark"></i> {event.category.name} </div>
                                            <div className="event--content-tickets">
                                                <a href="/" target="_blank" title="Tickets"> 
                                                    <i className="icofont-ticket"></i> 
                                                    {helpers.formatNumber(event.fee)}
                                                </a>
                                            </div>
                                        </div>
                                    </div>	
                                    <p><FormattedMessage id="component.text.created-by"/> {event.created_by}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
